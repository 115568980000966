import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        toggleOffcanvas: (state) => {
            state.isOpen = !state.isOpen;
        },
        closeOffcanvas: (state) => {
            state.isOpen = false;
        },
    },
});

// Export actions
export const { toggleOffcanvas, closeOffcanvas } = notificationsSlice.actions;

// Selector to get the current isOpen state
export const selectIsOffcanvasOpen = (state) => state.notifications.isOpen;

// Export the reducer
export default notificationsSlice.reducer;
