import axios from "axios";

const axiosClient = axios.create();

// setting base url globally
axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;

// no change
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// To share cookies to cross site domain, change to true.
axiosClient.defaults.withCredentials = false;

//All request will wait seconds before timeout
axiosClient.defaults.timeout = 0;

if (
  localStorage.getItem("accessToken") !== undefined ||
  localStorage.getItem("accessToken") !== null
) {
  axiosClient.defaults.headers["Authorization"] =
    localStorage.getItem("accessToken");
}

// api get request
export function getRequest(URL) {
  return axiosClient.get(`${URL}`).then((response) => response);
}

// api post request
export function postRequest(URL, payload) {
  return axiosClient.post(`${URL}`, payload).then((response) => response);
}

// api put request
export function putRequest(URL, payload) {
  return axiosClient.put(`${URL}`, payload).then((response) => response);
}

// api patch request
export function patchRequest(URL, payload) {
  return axiosClient.patch(`${URL}`, payload).then((response) => response);
}

// api delete request
export function deleteRequest(URL) {
  return axiosClient.delete(`${URL}`).then((response) => response);
}

// api multipart postrequest request
export function multiPartRequest(URL, payload) {

  axiosClient.interceptors.request.use(
    function (request) {
      request.headers["Content-Type"] = "multipart/form-data";
      return request;
    },
    null,
    { synchronous: true }
  );

  return axiosClient.post(`${URL}`, payload).then((response) => response);
}

// api multipart putrequest request
export function multiPartPutRequest(URL, payload) {

  axiosClient.interceptors.request.use(
    function (request) {
      request.headers["Content-Type"] = "multipart/form-data";
      return request;
    },
    null,
    { synchronous: true }
  );

  return axiosClient.put(`${URL}`, payload).then((response) => response);
}