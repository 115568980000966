/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ProgressBar from "../../../../components/progressbar/ProgressBar";
import RazorPayIcon from "../../../../assets/services/razorpayicon.svg"

// import { formateDateTwo } from "../../../../utilsfunction/Functions"

import { formateDateTwo } from "../../../../utilsfunction/Functions"

import CloseIcon from "../../../../assets/icons/close.svg"
import { LocalStorage } from "../../../../utilsfunction/Encrypt";
import "../../../../styles/postlogin/services.css"
import { useSelector } from "react-redux";
import FormError from "../../../../components/Alert/FormError";

import CouponDetails from "../../../../components/Cards/CouponDetails";

import MemberShipIcon from "../../../../assets/icons/subscriptionWhite.svg"
import { Link } from "react-router-dom";

import * as routePath from "../../../../routes"

const ServiceOffCanvas = ({ isOpen, setIsOpen, onClickFn }) => {

    const toggleOffCanvas = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Off-Canvas */}
            <div
                style={{ marginTop: "0px" }}
                className={`fixed right-0 justify-end inset-0 z-40 flex h-full ${isOpen ? "translate-x-0" : "translate-x-full"
                    } transition-transform duration-300`}
            >
                {/* Overlay for payment */}
                <div
                    className="fixed inset-0 bg-black opacity-90"
                // onClick={toggleOffCanvas}
                >
                </div>

                {/* Off-Canvas Content */}
                <div className="booking_offcanvas relative bg-white w-4/12 h-full p-0 right-0 fixed overflow-y-auto flex flex-col">
                    {/* Header */}
                    <div className="head flex justify-between items-center p-4">
                        <h3 className="heading">Pay for Booking</h3>
                        <img src={CloseIcon} alt="Close" className="cursor-pointer" onClick={toggleOffCanvas} width={"24px"} height={"24px"} />
                    </div>

                    {/* Body */}
                    <div className="body flex-1 flex flex-col justify-between border-t border-gray-200 p-4 overflow-y-auto">
                        {/* Payment Method */}
                        <div className="flex flex-col justify-between">
                            <div className="flex flex-col justify-between">
                                <div className="payment_method flex gap-4 items-center bg-white p-4 shadow rounded mb-4">
                                    <img
                                        src={RazorPayIcon}
                                        alt="Razorpay"
                                    />
                                    <span className="first_head">Payment Method:</span>{" "}
                                    <span className="second_head">Razorpay</span>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="foo_ter border-t border-gray-200 p-4 bg-white flex items-center gap-4">
                        <div className="w-6/12 mt-4">
                            <ProgressBar step1={true} step2={true} step3={false} />
                        </div>
                        <div className="w-6/12">
                            <button onClick={onClickFn} className="btn_primary">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ServiceOffCanvas;


// booking details offcanvas
export const ConfirmBookingOffcanvas = ({
    onClickFn,
    isOpenBookingConfirmation,
    isExpandedOnViewMore,
    setIsOpenBookingConfirmation,
    setIsExpandedOnViewMore,
    description,
    serviceTypePrice,
    serviceBasePrice,
    setCouponCode,
    checkCoupon,
    couponCheckMessage,
    couponCode,
    service,
    serviceType,
    isMember,
    location
}) => {
    const toggleOffCanvas = () => {
        setIsOpenBookingConfirmation(!isOpenBookingConfirmation);
    };

    const localstorage = new LocalStorage();

    let details = localstorage.getItem("bookingDetails") ? localstorage.getItem("bookingDetails") : []
    let addOns = localstorage.getItem("addOns") ? localstorage.getItem("addOns") : []

    const { offerPrice, originalPrice, totalPrice, couponDetails,
        gstPrice, membershipPrice, referralPrice, couponPrice,
        membershipPercent, referralPercent, couponPercent, offerPercent
    } = useSelector((state) => state?.priceReducer);

    let totalOfferAmount = membershipPrice + referralPrice + couponPrice + offerPrice

    const OpenAdminPage = (e) => {
        const queryParams = new URLSearchParams({ isMember: "false" }).toString();
        let baseURL = window.location.origin;
        window.open(`${baseURL}/${routePath.CustomerLayoutPath}/${routePath.subscriptionPath}?${queryParams}`);
    };

    return (
        <div>
            <div
                style={{ marginTop: "0px" }}
                className={`fixed inset-0 z-40 flex justify-end h-full ${isOpenBookingConfirmation ? "translate-x-0" : "translate-x-full"
                    } transition-transform duration-300`}
            >
                {/* Overlay for payment */}
                <div
                    className="fixed inset-0 bg-black opacity-90"
                // onClick={toggleOffCanvas}
                >
                </div>

                {/* Off-Canvas Content */}
                <div className="confirm_booking_offcanvas relative bg-white w-4/12 h-full p-0 overflow-y-auto">
                    <div className="head flex justify-between items-center p-4">
                        <h3 className="confirm_booking_heading">Confirm your Booking</h3>

                        <img src={CloseIcon} alt="Close" className="cursor-pointer" onClick={toggleOffCanvas} width={"24px"} height={"24px"} />
                    </div>

                    <div className="body flex flex-col justify-between border-t border-gray-200 p-4">
                        <div className="flex flex-col justify-between divide-y">
                            {/* Booking Details */}
                            <div className="mb-2">
                                <h3 className="booking_details_head mb-4">Booking Details</h3>
                                <div className="flex flex-col gap-1 mb-2">
                                    <span className="selected_services">Selected Service</span>
                                    <div className="flex items-center">
                                        <span className="text">{service?.name} </span> {serviceType && <><span className="round_dot"></span> <span className="text">{serviceType}</span></>}
                                    </div>
                                </div>

                                {/* <div className="flex flex-col gap-2 mb-1">
                                    <span className="services_details">Service Details</span>
                                    <div>
                                        <span className="text" dangerouslySetInnerHTML={{ __html: description }}></span>
                                    </div>
                                </div>

                                {isExpandedOnViewMore ? (
                                    <div>
                                        <p></p>
                                        <button
                                            className="view_button_more_less"
                                            onClick={() => setIsExpandedOnViewMore(false)}>
                                            View Less
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className="view_button_more_less"
                                        onClick={() => setIsExpandedOnViewMore(true)}>
                                        View More
                                    </button>
                                )} */}

                                <div className="flex flex-col gap-1 mb-2">
                                    <span className="working_time_details">Working Time</span>
                                    <div className="flex items-center">
                                        <span className="text">{formateDateTwo(details?.date) ? formateDateTwo(details?.date) : details.date} </span><span className="round_dot"></span> <span className="text">{details?.start_time}</span>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-1 mb-2">
                                    <span className="location_details">Location</span>
                                    <span className="text">{location ? location : details?.address + ", " + details?.flat_number + ", " + details?.state}</span>
                                </div>

                                {details?.note && <div className="flex flex-col gap-1 mb-2">
                                    <span className="note_details">Note</span>
                                    <span className="text">{details?.note}</span>
                                </div>}

                                {/* Membership Plan Box */}
                                {!isMember ? <div class="bg-green-500 p-4 pt-0 rounded-lg shadow-md">
                                    <div className="flex flex-col">
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center">
                                                <img src={MemberShipIcon} alt="" />
                                                <div>
                                                    <h2 className="text-white font-semibold">Membership</h2>
                                                </div>
                                            </div>
                                            <button onClick={() => { OpenAdminPage() }} className="bg-green-500 text-white border border-white font-semibold py-2 px-4 rounded-lg"
                                            >ADD</button>
                                        </div>

                                        <div>
                                            <p className="text-white text-sm">Save 15% on every service</p>
                                        </div>
                                    </div>
                                </div> : null}

                                <div className="flex flex-col mt-3">
                                    <div className="flex flex-col gap-1">
                                        <span className="cost_details">Service Price</span>
                                        <div className="flex flex-col gap-1 mb-2">
                                            <div className="flex gap-2 items-center">
                                                {/* <span className="text">{addOns[addOns?.length - 1]?.addOns?.length > 0 ? ("Rs " + addOns[addOns?.length - 1]?.basePrice) : serviceTypePrice ? serviceTypePrice : serviceBasePrice}
                                            </span> */}
                                                <span className="text">{"Rs "}{originalPrice}
                                                </span>
                                                <span className="round_dot"></span>
                                                <span className="text">{details?.coupon_applied ? details?.coupon_applied : "NA"}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {membershipPrice ? <>
                                        <span className="cost_details">Membership Offer Price</span>
                                        <div className="flex flex-col gap-1 mb-2">
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{membershipPrice ? membershipPrice?.toFixed(2) + ` (${membershipPercent}%)` : ""}</span></div>
                                        </div>
                                    </> : null}
                                    {referralPrice ? <>
                                        <span className="cost_details">Referral Offer Price</span>
                                        <div className="flex flex-col gap-1 mb-2">
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{referralPrice ? referralPrice?.toFixed(2) + ` (${referralPercent}%)` : ""}</span></div>
                                        </div>
                                    </> : null}
                                    {couponPrice ? <>
                                        <span className="cost_details">Coupon Offer Price</span>
                                        <div className="flex flex-col gap-1 mb-2">
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{couponPrice ? couponPrice?.toFixed(2) + ` (${couponPercent}%)` : ""}</span></div>
                                        </div>
                                    </> : null}
                                    {offerPrice ? <>
                                        <span className="cost_details">Festive Offer Price</span>
                                        <div className="flex flex-col gap-1 mb-2">
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{offerPrice ? offerPrice?.toFixed(2) + ` (${offerPercent}%)` : ""}</span></div>
                                        </div>
                                    </> : null}

                                    {totalOfferAmount ? <>
                                        <span className="cost_details">Total Saving</span>
                                        <div className="flex flex-col gap-1 mb-2">
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{totalOfferAmount ? totalOfferAmount?.toFixed(2) : ""}</span></div>
                                        </div>
                                    </> : null}

                                    {addOns[addOns?.length - 1]?.addOns?.length > 0 && <><span className="cost_details">Add Ons</span>
                                        {addOns[addOns?.length - 1]?.addOns?.map((value) => {
                                            return <div className="flex gap-4">
                                                <div>
                                                    {/* <span className="text">{"Service name: "}</span> */}
                                                    <span className="text">{value?.AddOn?.name}</span>
                                                </div>
                                                <div>
                                                    {/* <span className="text">{"Price: "}</span> */}
                                                    <span className="text">{"Rs "}{value?.AddOn?.price}</span>
                                                </div>
                                            </div>
                                        })}
                                    </>}

                                    <div className="flex flex-col gap-1">
                                        <span className="cost_details mt-0">GST (18%)</span>
                                        <div className="flex flex-col gap-1 mb-3">
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{gstPrice?.toFixed(2)}</span></div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-1">
                                        <span className="cost_details mt-0">Total Cost</span>
                                        <div className="flex flex-col gap-1 mb-3">
                                            {/* <div className="flex gap-2 items-center"><span className="text">{addOns[addOns?.length - 1]?.addOns?.length > 0 ? "Rs " + addOns[addOns?.length - 1]?.totalPrice : serviceTypePrice ? serviceTypePrice : serviceBasePrice}</span><span className="round_dot"></span> <span className="text">{details?.coupon_applied ? details?.coupon_applied : "NA"}</span></div> */}
                                            <div className="flex gap-2 items-center"><span className="text">{"Rs "}{totalPrice?.toFixed(2)}</span><span className="round_dot"></span> <span className="text">{details?.coupon_applied ? details?.coupon_applied : "NA"}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Service Agent Details */}
                            {/* <div className="mb-2 pt-4">
                                <h3 className="booking_details_head mb-3">Service Agent Details</h3>
                                <div className="flex flex-col gap-2 mb-3">
                                    <span className="service_agent_name">Service Agent Name</span>
                                    <div>
                                        <span className="text">{details?.agent_name}</span>
                                    </div>
                                </div>
                            </div> */}

                            {/* Payment Details */}
                            <div className="mb-2 pt-4">
                                <h3 className="booking_details_head mb-3">Payment Details</h3>
                                <div className="flex flex-col gap-2 mb-3">
                                    <span className="service_payment_method">Payment Method</span>
                                    <div>
                                        <span className="text">{details?.payment_method ? details?.payment_method : "Razorpay"}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Coupon Code Input */}
                            <div className="service_coupon mb-4 bg-white p-4 shadow">
                                <div className="flex relative gap-2">
                                    <input
                                        type="text"
                                        className="input_field border border-black-300 p-2 rounded-l w-full"
                                        placeholder=" "
                                        onChange={(e) => setCouponCode(e.target.value)}
                                    />
                                    <span className="input_placeholder input_placeholder_coupon">Enter Coupon Code</span>
                                    <button className="service_coupon_button" onClick={checkCoupon}>
                                        Apply
                                    </button>
                                </div>
                                {couponCheckMessage && <FormError error={couponCheckMessage} />}
                            </div>

                            {couponDetails && <CouponDetails
                                title={"Applied Coupon " + couponCode}
                                discount={couponDetails?.coupon?.discount}
                            />}
                        </div>

                        {/* Navigation Buttons */}
                        <div className="foo_ter flex justify-between gap-4 items-center p-4"
                            style={{ background: "#F4F4F4" }}
                        >
                            <div className="w-6/12 mt-4"><ProgressBar step1={true} step2={true} step3={true} /></div>
                            <div className="w-6/12 flex items-center">
                                <button onClick={onClickFn} className="btn_primary">
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



