import React from 'react';
import fbIcon from "../../assets/icons/facebook.png"
import instaIcon from "../../assets/icons/insta.png"
import ytIcon from "../../assets/icons/youtube.png"
import chatIcon from "../../assets/icons/snapchat.png"
import linkIcon from "../../assets/icons/linkedin.png"
import pinIcon from "../../assets/icons/pinterest.png"
import twitterIcon from "../../assets/icons/X.png"
import tbIcon from "../../assets/icons/tumblr.png"
import rdIcon from "../../assets/icons/reddit.png"
import whatsapp from "../../assets/icons/whatsapp.svg"
import gmail from "../../assets/icons/gmail.svg"

const socialMediaLinks = {
    facebook: "https://www.facebook.com/dospeedo",
    instagram: "https://www.instagram.com/dospeedo/",
    twitter: "https://twitter.com/intent/tweet?text=",
    linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=",
    pinterest: "https://pinterest.com/pin/create/button/?url=",
    tumblr: "https://www.tumblr.com/widgets/share/tool?canonicalUrl=",
    discord: "https://discord.com/channels/@me",
};

const ReferSocialModal = ({ closeModal }) => {
    // const shareUrl = encodeURIComponent("https://www.dospeedo.com");
    const message = encodeURIComponent("Check out this amazing site that I found! It's really useful: ");
    
    const inviteMessage = "Hey! Check out this amazing platform. Join us at:";
    const appLink = "https://play.google.com/store/apps/details?id=com.dospeedo";
    const websiteLink = "https://dospeedo.com/";
    const fullMessage = `${inviteMessage} ${appLink} or ${websiteLink}`;

    // const shareOnWhatsApp = () => {
    //     const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(fullMessage)}`;
    //     window.open(url, "_blank");
    // };

    const shareOnWhatsApp = () => {
        const url = `https://wa.me/?text=${encodeURIComponent(fullMessage)}`;
        window.open(url, "_blank");
    };

    const shareOnEmail = () => {
        const subject = "Join this amazing platform!";
        const url = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(fullMessage)}`;
        window.location.href = url;
    };
      
    const shareOnFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(websiteLink)}&quote=${encodeURIComponent(inviteMessage)}`;
        window.open(url, "_blank");
    };

    const shareOnInstagram = () => {
        alert("Instagram sharing requires manual posting. Copy the message below:\n\n" + fullMessage);
    };  
      
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50 overflow-hidden">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <h2 className="heading_text1 mb-4">Refer a Friend</h2>
                <p className="content_text mb-6">
                    Share this link with your friends and help them discover us!
                </p>
                <div className="grid grid-cols-6 gap-4 mb-6">
                    <button
                        onClick={shareOnFacebook}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={fbIcon} alt="" width={"250"} height={"250"} />
                    </button>
                    <button
                        onClick={shareOnInstagram}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={instaIcon} alt="" width={"250"} height={"250"} />
                    </button>
                    <button
                        onClick={shareOnWhatsApp}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={whatsapp} alt="" width={"250"} height={"250"} style={{ border: '1px solid green', borderRadius: '50px' }}/>
                    </button>
                    <button
                        onClick={shareOnEmail}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={gmail} alt="" width={"250"} height={"250"} />
                    </button>
                </div>
                <button
                    onClick={closeModal}
                    className="w-full btn_primary"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ReferSocialModal