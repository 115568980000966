/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Header from "../../../components/Header/index";
import Footer from "../../../components/Footer/Footer";
import CompanyLogo from "../../../assets/dospeedo.svg"

import BlogCard from "../../../components/Cards/BlogCard";
import { getRequest } from "../../../interceptor/AxiosConfig";
import { homePageDataApiEndpoint } from "../../../apis/prelogin/PreloginApis";
// import ErrorMessageHandling from "../../../utilsfunction/ErrorHandling";
import { formatDate } from "../../../utilsfunction/Functions";
import { useLocation, useNavigate } from "react-router-dom";

// testimonials
import RatingStarIconGray from "../../../assets/home/testimonials/stargray.svg";
import RatingStarIconYellow from "../../../assets/home/testimonials/staryellow.svg";
import InvertedComma from "../../../assets/home/testimonials/invertedcomma.svg";
import NextIcon from "../../../assets/home/testimonials/nexticon.svg";
import PreviousIcon from "../../../assets/home/testimonials/previousicon.svg";

import AboutUsImg from "../../../assets/aboutus.png"
import BenefitImg from "../../../assets/aboutbenefit.png"

import * as routePath from "../../../routes";
import Error from "../../../components/Alert/Error";

import BallonIcon from "../../../assets/about/rightTick.svg"
import { setMessage } from "../../../components/Reducers/MessageReducer";
import { useDispatch } from "react-redux";

function AboutUs() {
    const navigate = useNavigate()
    const base_url = process.env.REACT_APP_API_URL + "uploads/";

    const [blogs, setBlogsdata] = useState(null);
    const [testimonialData, setTestimonial] = useState([]);

    const [loader, setLoader] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false)

    const getHomeData = async () => {
        setLoader(true);
        await getRequest(`${homePageDataApiEndpoint}`)
            .then((res) => {
                if (res.status === 200) {
                    setLoader(false);
                    setBlogsdata(res.data.data.blogs);
                    setTestimonial(res.data.data.testimonials);
                }
            })
            .catch((error) => {
                setErrorMessage(null)
                // setErrorMessage(ErrorMessageHandling(error));
            });
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide
            ? testimonialData.length - 1
            : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const handleNext = () => {
        const isLastSlide = currentIndex === testimonialData.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    // calling api home component loads
    useEffect(() => {
        getHomeData();
    }, []);

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!location.pathname.includes("/dospeedo/login")) {
            dispatch(setMessage(null));
        }
    }, [location.pathname.includes("/dospeedo/login")])

    return (
        <div>
            {errorMessage && <Error errorMessage={errorMessage} />}
            <Header />
            {loader ? <></>
                :
                <div className="container relative mx-auto bg-gray-100 flex flex-col gap-6 px-0 min-h-screen">
                    <div className="bgColor heading_text h-64 p-6 lg:pl-24 xl:pl-24 pl-5 text-left">
                        About Us
                    </div>
                    <div className="container relative mx-auto font-sans bg-gray-100">
                        <div className="aboutus flex flex-col items-center bg-white p-4 border border-gray-100">
                            <div className="bg-white w-full">
                                <div className="relative">
                                    <img
                                        src={AboutUsImg}
                                        alt="Cleaning Service"
                                        className="w-full h-auto "
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-70"></div>
                                    <div className="absolute inset-0 flex items-center justify-center logoImageBox">
                                        <img src={CompanyLogo} alt="logo" />
                                    </div>
                                </div>

                                <div className="py-4 relative z-10">
                                    <p className="mb-4 content_text">
                                        At DoSpeedo, we aim at redefining the process and way you hire home helpers.
                                        As India’s most trusted maid hiring platform, we connect individuals with verified and
                                        highly trained maids that resonate with your cleaning needs. No matter,
                                        if it’s a commercial space or a residential one, get trusted maids at just a single click.
                                    </p>
                                    <p className="content_text mb-4">
                                        The hiring process on DoSpeedo is simple and intuitive. Our platform is built in such
                                        as way that streamlines the search for domestic help, ensuring a perfect match when you
                                        need it most. Whether it's a last-minute cleaning service for a special event or your
                                        regular home cleaning, our tailored services help you stay stress-free.
                                    </p>
                                    <p className="content_text">
                                        All maids registered with us undergo a strict verification process. This includes Aadhar
                                        checks, background screenings and other things to ensure a safe and reliable experience
                                        for our clients. Our commitment to transparent pricing, personalized services, and customer
                                        satisfaction makes DoSpeedo the go-to destination for all your cleaning needs. Finding the
                                        right help is now just a few clicks away. Let us take the stress out of hiring, so you can enjoy
                                        a cleaner, more comfortable home.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Content Section */}
                    <div className="benefitsection container mx-auto font-sans bg-gray-100">
                        <div className="container mx-auto py-4 px-4 bg-white p-4 border border-gray-100">
                            <div className="mx-auto">
                                {/* Benefits Section */}
                                <div className="grid sm:grid-cols-1 md:grid-cols-12 gap-4">
                                    <div className="md:col-span-4">
                                        <img
                                            src={BenefitImg}
                                            alt="benefits"
                                            width="100%"
                                            className=""
                                        />
                                    </div>
                                    <div className="md:col-span-8 flex flex-col gap-4">
                                        <h2 className="heading_text1">Benefits</h2>
                                        <div className="flex flex-col gap-4 h-auto list-disc list-inside text-gray-700 benefitList">
                                            <div className="flex gap-4 items-center md:h-12 lg:h-12 xl:h-12 h-7 content_text1"><img src={BallonIcon} alt="" width={"60"} height={"60"} /><span>Only Verified and Trusted Maids</span></div>
                                            <div className="flex gap-4 items-center md:h-12 lg:h-12 xl:h-12 h-7 content_text1"><img src={BallonIcon} alt="" width={"60"} height={"60"} /><span>Flexible Services for Every Need</span></div>
                                            <div className="flex gap-4 items-center md:h-12 lg:h-12 xl:h-12 h-7 content_text1"><img src={BallonIcon} alt="" width={"60"} height={"60"} /><span>Easy and Intuitive Hiring Process</span></div>
                                            <div className="flex gap-4 items-center md:h-12 lg:h-12 xl:h-12 h-7 content_text1"><img src={BallonIcon} alt="" width={"60"} height={"60"} /><span>Transparent Pricing, No Hidden Costs</span></div>
                                            <div className="flex gap-4 items-center md:h-12 lg:h-12 xl:h-12 h-7 content_text1"><img src={BallonIcon} alt="" width={"60"} height={"60"} /><span>Quick Access to Local Maids</span></div>
                                            <div className="flex gap-4 items-center md:h-12 lg:h-12 xl:h-12 h-7 content_text1"><img src={BallonIcon} alt="" width={"60"} height={"60"} /><span>Customizable Cleaning Plans Anytime, Anywhere</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Testimonials */}
                    <section className="container mx-auto" id="testimonials">
                        <div className="testimonials_section flex flex-col gap-4 bg-white p-4 border border-gray-100"
                            style={{ marginTop: "0rem" }}
                        >
                            <span className="testimonial_head">Testimonials</span>
                            <h2 className="testimonial_desc">See what users say about us</h2>
                            <div className="testimonial_body">
                                <div className="testimonial_rank flex justify-center">
                                    {[...Array(5)].map((_, i) => (
                                        <span key={i} className={`h-6 w-6`}>
                                            <img
                                                src={`${i < testimonialData[currentIndex]?.rating
                                                    ? RatingStarIconYellow
                                                    : RatingStarIconGray
                                                    }`}
                                                alt={"Rating"}
                                            />
                                        </span>
                                    ))}
                                </div>
                                <img
                                    src={InvertedComma}
                                    alt={"Inverted Comma"}
                                    className="inverted_comma"
                                    width={"120px"}
                                />
                                <div className="testimonial_text pb-4">
                                    <p className="pb-4">
                                        {testimonialData[currentIndex]?.review}
                                    </p>
                                    <hr></hr>
                                </div>
                                <div className="flex testimonial_user_section items-center gap-4">
                                    <button onClick={handlePrev} className="p-2 btn_previous">
                                        <img src={PreviousIcon} alt={"Previous"} />
                                    </button>
                                    <div className="bg-white items-center flex gap-2 md:gap-6 lg:gap-8">
                                        {testimonialData?.map((_, i) => {
                                            return (
                                                <div
                                                    className={`${currentIndex === i
                                                        ? "flex flex-col items-center gap-2"
                                                        : ""
                                                        }`}
                                                >
                                                    <img
                                                        src={`${base_url}${testimonialData[i].User.profile_picture}`}
                                                        alt={""}
                                                        className={`${currentIndex === i
                                                            ? "active_testimonial rounded-full mx-auto"
                                                            : "not_activetestimonials h-16 w-16"
                                                            }`}
                                                    />
                                                    {/* {currentIndex === i && (
                                                        <h4 className="testimonial_user_name">
                                                            {testimonialData[i].User.full_name}
                                                        </h4>
                                                    )} */}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <button onClick={handleNext} className="p-2 btn_next">
                                        <img src={NextIcon} alt={"Next"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Blog Section  */}
                    <section section className="container mx-auto pb-12" id="blogs">
                        <div className="blog_section flex flex-col gap-4 items-center">
                            <span className="blogs_head">Blogs</span>
                            <h2 className="text-3xl font-bold text-gray-800 mb-2">
                                Expert Advice for a Spotless Home
                            </h2>
                            <div className="blog_section_card grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                                {blogs?.map((value, index) => {
                                    return (
                                        <BlogCard
                                            date={formatDate(value.created_at)}
                                            heading={value.title}
                                            blogImg={value.banner_image}
                                            onClickFn={() => navigate(`/${routePath.blogDetailsPath}/${value.id}`)}
                                        />
                                    );
                                })}
                            </div>
                            {/* <div>
                                <button className="blog_button" onClick={() => navigate(`/${routePath.blogDetailsPath}`)}>See All</button>
                            </div> */}
                        </div>
                    </section>
                </div>}
            <Footer />
        </div>
    );
}

export default AboutUs;
