import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header/index";
import Footer from "../components/Footer/Footer";

const NotFound = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const styles = {
    container: {
    //   backgroundColor: '#0c212a',
      color: 'white',
      textAlign: 'center',
      padding: '100px 20px',
      minHeight: '60vh',
    },
    heading: {
      fontSize: '3rem',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#0c212a',
    },
    paragraph: {
      fontSize: '1.25rem',
      marginBottom: '30px',
      color: '#0c212a'
    },
    button: {
      display: 'inline-block',
      marginTop: '20px',
      padding: '10px 20px',
      fontSize: '1rem',
      color: 'white',
      backgroundColor: '#0c212a',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#008ba3',
    },
  };

  const handleNavigate = () => {
    navigate('/'); // Navigates to the home page
  };

  return (
    <>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.heading}>404 - Page Not Found</h1>
        <p style={styles.paragraph}>
          Oops! The page you are looking for does not exist or has been moved.
        </p>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
          onClick={handleNavigate}
        >
          Go Back to Home
        </button>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
