import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Error = ({ errorMessage }) => {
  useEffect(() => {
    if (errorMessage) {
      toast.error(`Error! ${errorMessage}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        error: {
          style: {
            color: "#EA2A2A",
          },
        },
      });
    }
  }, [errorMessage]);

  return (
    <div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default Error;
