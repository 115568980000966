/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import { Link, useNavigate } from "react-router-dom";
import * as routePath from "../../routes";
import { postRequest } from "../../interceptor/AxiosConfig";
import * as apiEndpoint from "../../apis/prelogin/PreloginApis";
import { useEffect, useState } from "react";
import ErrorMessageHandling from "../../utilsfunction/ErrorHandling";
import Error from "../../components/Alert/Error";
import CountryCodeAndFlag from "../../utilsfunction/CountryCodeAndFlag";
import {
  isValidEmail,
  isValidPhoneNumber,
} from "../../utilsfunction/RegexFunc";
import FormError from "../../components/Alert/FormError";
import { useDispatch } from "react-redux";
import { setMessage } from "../../components/Reducers/MessageReducer";
import EyeClose from "../../assets/prelogin/eye_close.svg";
import EyeOpen from "../../assets/prelogin/eye_open.svg";
import CompanyLogo from "../../assets/dospeedo.svg";
// import ArrowBtn from "../../assets/icons/left_arrow.svg";
import VectorGrp from "../../assets/prelogin/register_graphic.png";
import BackIcon from "../../assets/prelogin/back_arrow.svg";

import { signInWithPopup } from 'firebase/auth';
import { appleProvider, auth, provider } from '../../helper/firebase';

const Registration = () => {
  let countryCodeWithFlag = CountryCodeAndFlag();
  const { dial_code, flag } = countryCodeWithFlag[102];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialState = {
    full_name: "",
    email: "",
    country_code: "+91",
    phone_number: "",
    address: "",
    password: "",
    confirm_password: "",
    lat: null,
    lng: null,
    login_type: null,
    device_token: null,
    device_id: null,
    device_type: null,
    device_version: null,
  };
  const [formValues, setFormValues] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // post api call for creating the user
  const registerUser = async () => {
    await postRequest(`${apiEndpoint.registerAPIEndpoint}`, {
      full_name: formValues?.full_name,
      email: formValues?.email,
      country_code: formValues?.country_code,
      phone_number: formValues?.phone_number,
      address: formValues?.address,
      password: formValues?.password,
      lat: null,
      lng: null,
      login_type: null,
      device_token: null,
      device_id: null,
      device_type: null,
      device_version: null,
    })
      .then((res) => {
        dispatch(setMessage(res.data.message));
        // setErrorMessage("");
        navigate(
          `/${routePath.preloginPath}/${routePath.OTPVerificationPath}`,
          {
            state: {
              otpApiUrl: `${apiEndpoint.verifyRegisterOTPAPIEndpoint}`,
              email: formValues?.email,
              country_code: formValues?.country_code,
              phone_number: formValues?.phone_number,
              isFp: false,
            },
          }
        );
      })
      .catch((error) => {
        setErrorMessage(ErrorMessageHandling(error));
      });
  };

  // setting the fields values
  const onHandleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // validating the fields
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email Id is required!";
    } else if (!isValidEmail(values.email)) {
      errors.email = "Email Id is not valid!";
    }
    if (!values.full_name.trim()) {
      errors.full_name = "Full name is required!";
    }
    if (!values.phone_number.trim()) {
      errors.phone_number = "Mobile number is required!";
    } else if (!isValidPhoneNumber(values.phone_number)) {
      errors.phone_number =
        "Mobile number is not valid, it should be 10 digits with no characters!";
    }
    if (!values.country_code.trim()) {
      errors.country_code = "Country code is required!";
    }
    if (!values.address.trim()) {
      errors.address = "Address is required!";
    }
    if (!values.password.trim()) {
      errors.password = "Password is required!";
    }
    if (!values.confirm_password.trim()) {
      errors.confirm_password = "Confirm Password is required!";
    } else if (values.password.trim() !== values.confirm_password.trim()) {
      errors.confirm_password = "Password and Confirm Password is not same!";
    }
    return errors;
  };

  // function for handling create button event
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    // if there is no errors registerUser() function will be called
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      registerUser();
    }
  }, [formErrors, isSubmit]);

  // const handleMobileCode = (arg) => {
  //   setCountryCode(arg.dial_code);
  //   setCountryFlag(arg.flag);
  //   setFormValues({ ...formValues, ["country_code"]: arg.dial_code });
  //   document.getElementById("dropdown_phone").classList.add("hidden");
  // };

  // const [showCountryCodeDrop, setShowCountryCodeDrop] = useState(false);

  // const translateFunction = () => {
  //   setShowCountryCodeDrop(!showCountryCodeDrop);
  // };

  // const dropdownRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowCountryCodeDrop(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // registration with google account
  const handleGoogleSignup = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        sendUserDataToBackend(user, 'google');
      })
      .catch((error) => {
        if (error.response) {
          // setErrorMessage("")
        }
        // setErrorMessage(SocialSignInErrorHandling(error));
      });
  };

  // registration with apple account
  const handleAppleSignup = () => {
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        sendUserDataToBackend(user, 'apple');
      })
      .catch((error) => {
        if (error.response) {
          // setErrorMessage("")
        }
        // setErrorMessage(SocialSignInErrorHandling(error));
      });
  };

  // sending the user data to the backend
  const sendUserDataToBackend = (user, provider) => {
    const social_id = user.uid || user.localId;
    const login_with = provider;
    const device_token = null;
    const full_name = user.displayName || 'Unknown';
    const email = user.email || '';
    const profile_picture = user.photoURL || '';
    const token_type = "web";
    const device_version = null;
    const address = user.address || null;
    const lat = null;
    const lng = null;
    const phone = user.phone || null;

    postRequest(`${apiEndpoint.registerWithGoogleAPIEndpoint}`, {
      social_id,
      login_with,
      device_token,
      full_name,
      email,
      address,
      lat,
      lng,
      profile_picture,
      token_type,
      device_version,
    })
      .then((res) => {
        dispatch(setMessage(res.data.message));
        // setErrorMessage("");
        navigate(
          `/${routePath.preloginPath}/${routePath.OTPVerificationPath}`,
          {
            state: {
              otpApiUrl: `${apiEndpoint.verifyRegisterOTPAPIEndpoint}`,
              email: email,
              country_code: "+91",
              phone_number: phone,
              isFp: false,
            },
          }
        );

      })
      .catch((error) => {
        setErrorMessage(ErrorMessageHandling(error));
      });
  };

  return (
    <section className="container mx-auto">
      <div className="relative grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 overflow-hidden py-24 registerOuter">
        <div className="registerInner">
          <div className="registerContent text-white">
            <div class="flex justify-center items-center mb-12">
              <img src={CompanyLogo} alt="Company Logo" />
            </div>
            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Mission
              </h2>
              <p>
                <strong>
                  “Simplifying cleaning and empowering lives with a click”
                </strong>
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Vision
              </h2>
              <p>
                Building a community where cleaning professionals are valued and
                empowered to make a difference by providing solution to cleaning
                challenges
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-10">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Services
              </h2>
              <ul>
                <li>Brooming and Mopping</li>
                <li>Residential Cleaning</li>
                <li>Utensils Cleaning</li>
                <li>Commercial Cleaning</li>
                <li>Kitchen Cleaning </li>
                <li>Full Home Cleaning</li>
                <li>Bathroom Cleaning</li>
                <li>Special Occasion Service</li>
              </ul>
            </div>
          </div>
          <div className="registerGrapic">
            <img src={VectorGrp} alt="Banner Graphic Img" />
          </div>
        </div>

        <div className="registerFormWrap">
          <div className="flex flex-col gap-0">
            <div className="mx-auto w-full login_heading">
              <div className="prelogin_heading_first flex justify-content-center align-items-center">
                <span>
                  <img
                    onClick={() => {
                      navigate(-1);
                    }}
                    src={BackIcon}
                    alt="Back"
                    className=" cursor-pointer"
                  />
                </span>
                <span className="">Sign Up</span>
              </div>
            </div>
            <div className="registration_form_box signUpBox w-full mt-4 mb-4 mx-auto bg-white border border-gray-200 shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
              <div className="p-4 pt-14">
                <div className="mt-0 pb-14">
                  {/* <button
                  type="button"
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <svg
                    className="w-4 h-auto"
                    width="46"
                    height="47"
                    viewBox="0 0 46 47"
                    fill="none"
                  >
                    <path
                      d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                      fill="#34A853"
                    />
                    <path
                      d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                      fill="#EB4335"
                    />
                  </svg>
                  Google
                </button>

                <button
                  type="button"
                  className="w-full py-3 px-4 mt-2 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <svg
                    className="w-4 h-auto"
                    width="46"
                    height="47"
                    viewBox="-1.5 0 20 20"
                    fill="none"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-102.000000, -7439.000000)"
                        fill="#000000"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M57.5708873,7282.19296 C58.2999598,7281.34797 58.7914012,7280.17098 58.6569121,7279 C57.6062792,7279.04 56.3352055,7279.67099 55.5818643,7280.51498 C54.905374,7281.26397 54.3148354,7282.46095 54.4735932,7283.60894 C55.6455696,7283.69593 56.8418148,7283.03894 57.5708873,7282.19296 M60.1989864,7289.62485 C60.2283111,7292.65181 62.9696641,7293.65879 63,7293.67179 C62.9777537,7293.74279 62.562152,7295.10677 61.5560117,7296.51675 C60.6853718,7297.73474 59.7823735,7298.94772 58.3596204,7298.97372 C56.9621472,7298.99872 56.5121648,7298.17973 54.9134635,7298.17973 C53.3157735,7298.17973 52.8162425,7298.94772 51.4935978,7298.99872 C50.1203933,7299.04772 49.0738052,7297.68074 48.197098,7296.46676 C46.4032359,7293.98379 45.0330649,7289.44985 46.8734421,7286.3899 C47.7875635,7284.87092 49.4206455,7283.90793 51.1942837,7283.88393 C52.5422083,7283.85893 53.8153044,7284.75292 54.6394294,7284.75292 C55.4635543,7284.75292 57.0106846,7283.67793 58.6366882,7283.83593 C59.3172232,7283.86293 61.2283842,7284.09893 62.4549652,7285.8199 C62.355868,7285.8789 60.1747177,7287.09489 60.1989864,7289.62485"
                            id="apple-[#173]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  Apple
                </button>

                <div className="py-3 flex items-center text-xs text-gray-400 before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6">
                  Or continue without social account
                </div> */}

                  <form>
                    <div className="formGrid grid gap-y-3">
                      <div>
                        <div className="relative">
                          <label htmlFor="">Enter Name</label>
                          <input
                            type="text"
                            id="name"
                            name="full_name"
                            className={`py-3 px-4 block w-full text-sm ${formErrors?.full_name
                              ? `form_error_border`
                              : `border-gray-200`
                              } rounded-lg`}
                            required
                            placeholder="Enter Full Name"
                            aria-describedby="name-error"
                            onChange={(e) => {
                              onHandleChange(e);
                            }}
                          />
                          <FormError error={formErrors.full_name} />
                        </div>
                      </div>
                      <div>
                        <div className="relative">
                          <label htmlFor="">Email Address</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className={`py-3 px-4 block w-full ${formErrors?.email
                              ? `form_error_border`
                              : `border-gray-200`
                              } rounded-lg text-sm`}
                            required
                            placeholder="Email Address"
                            aria-describedby="email-error"
                            onChange={(e) => {
                              onHandleChange(e);
                            }}
                          />
                          <FormError error={formErrors.email} />
                        </div>
                      </div>
                      <div className="relative">
                        <label htmlFor="">Mobile Number</label>
                        <div className="flex items-center">
                          <button
                            id="dropdown-phone-button"
                            data-dropdown-toggle="dropdown-phone"
                            className={`py-3 px-4 flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm text-center bg-white-100 border-r-0 border ${formErrors?.phone_number
                              ? `form_error_border_code`
                              : `border-gray-200`
                              } rounded-s-lg `}
                            type="button"
                            // onClick={() => translateFunction()}
                            disabled
                          >
                            {/* {countryCode}&nbsp;{countryFlag} */}
                            {flag}&nbsp;{dial_code}
                            <svg
                              className="w-2.5 h-2.5 ms-2.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </button>
                          {/* {showCountryCodeDrop ? (
                        <div
                          id="dropdown_phone"
                          ref={dropdownRef}
                          className="z-10 mobile_code bg-white divide-y divide-gray-100 rounded-lg shadow w-100 dark:bg-gray-700"
                        >
                          <ul
                            className="mobile_code_list py-2 text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdown-phone-button"
                          >
                            {countryCodeWithFlag?.map((data) => {
                              return (
                                <li
                                  className={`${
                                    countryCode === data.dial_code ? `active` : ``
                                  }`}
                                >
                                  <button
                                    type="button"
                                    className="inline-flex w-full px-4 py-2 text-sm text-gray-700  dark:text-gray-200"
                                    role="menuitem"
                                    onClick={(e) => {
                                      handleMobileCode(data);
                                    }}
                                  >
                                    <span className="inline-flex items-center">
                                      {data.flag}&nbsp;
                                      {data.name}&nbsp;{data.dial_code}
                                    </span>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        ""
                      )} */}
                          <div className="relative w-full">
                            <input
                              type="text"
                              id="phone_number"
                              name="phone_number"
                              className={`block p-2.5 z-20 py-3 px-4 block w-full text-sm ${formErrors?.phone_number
                                ? `form_error_border_input`
                                : `border-gray-200`
                                } rounded-e-lg border-s-0 border`}
                              required
                              placeholder="Phone Number"
                              aria-describedby="phone-error"
                              onChange={(e) => {
                                onHandleChange(e);
                              }}
                            />
                          </div>
                        </div>
                        {formErrors.phone_number ? (
                          <FormError error={formErrors.phone_number} />
                        ) : // formErrors.country_code ? (
                          //   <FormError error={formErrors.country_code} />
                          // ) :
                          null}
                      </div>

                      <div className="relative">
                        <label htmlFor="">Address</label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className={`py-3 px-4 block w-full ${formErrors?.address
                            ? `form_error_border`
                            : `border-gray-200`
                            } rounded-lg text-sm `}
                          required
                          placeholder="Address"
                          aria-describedby="address-error"
                          onChange={(e) => {
                            onHandleChange(e);
                          }}
                        />
                        <FormError error={formErrors.address} />
                      </div>

                      <div className="relative">
                        <label htmlFor="">Password</label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className={`py-3 px-4 block w-full ${formErrors?.password
                            ? `form_error_border`
                            : `border-gray-200`
                            } rounded-lg text-sm `}
                          required
                          placeholder="Password"
                          aria-describedby="password-error"
                          onChange={(e) => {
                            onHandleChange(e);
                          }}
                        />
                        {formValues?.password ? (
                          <div
                            className="absolute inset-y-0 right-0 top-6 flex items-center px-2 cursor-pointer"
                            onClick={handlePassword}
                          >
                            {showPassword ? (
                              <img src={EyeOpen} alt="Hide Password" />
                            ) : (
                              <img src={EyeClose} alt="See Password" />
                            )}
                          </div>
                        ) : null}
                        <FormError error={formErrors.password} />
                      </div>

                      <div className="relative">
                        <label htmlFor="">Re-Enter Password</label>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirm_password"
                          name="confirm_password"
                          className={`py-3 px-4 block w-full ${formErrors?.confirm_password
                            ? `form_error_border`
                            : `border-gray-200`
                            } rounded-lg text-sm `}
                          required
                          placeholder="Confirm Password"
                          aria-describedby="confirm_password-error"
                          onChange={(e) => {
                            onHandleChange(e);
                          }}
                        />
                        {formValues?.confirm_password ? (
                          <div
                            className="absolute inset-y-0 right-0 top-6 flex items-center px-2 cursor-pointer"
                            onClick={handleConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <img src={EyeOpen} alt="Hide Password" />
                            ) : (
                              <img src={EyeClose} alt="See Password" />
                            )}
                          </div>
                        ) : null}
                        <FormError error={formErrors.confirm_password} />
                      </div>

                      {errorMessage && <Error errorMessage={errorMessage} />}
                      <div className="signupDeclaration">
                        <p>
                          By tapping “Sign Up” you agree to our{" "}
                          <Link className="underline" to={`/${routePath.termsAndConditionsPath}`}>Terms and Conditions </Link>
                          and <Link className="underline" to={`/${routePath.privacyPolicyPath}`}>Privacy Policy</Link>.
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="bg_button_color w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white  disabled:opacity-50 disabled:pointer-events-none"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Sign Up
                      </button>

                      <div className="otherSignupMethod text-center">
                        <span>Or continue with social account</span>
                        <div className="otherMethodBox flex justify-around">
                          <span>
                            <button
                              type="button"
                              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                              onClick={() => handleGoogleSignup()}
                            >
                              <svg
                                className="w-4 h-auto"
                                width="46"
                                height="47"
                                viewBox="0 0 46 47"
                                fill="none"
                              >
                                <path
                                  d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                                  fill="#EB4335"
                                />
                              </svg>
                              Google
                            </button>
                          </span>
                          <span>
                            <button
                              type="button"
                              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                              onClick={() => handleAppleSignup()}
                            >
                              <svg
                                className="w-4 h-auto"
                                width="46"
                                height="47"
                                viewBox="-1.5 0 20 20"
                                fill="none"
                              >
                                <g
                                  id="Page-1"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="Dribbble-Light-Preview"
                                    transform="translate(-102.000000, -7439.000000)"
                                    fill="#000000"
                                  >
                                    <g
                                      id="icons"
                                      transform="translate(56.000000, 160.000000)"
                                    >
                                      <path
                                        d="M57.5708873,7282.19296 C58.2999598,7281.34797 58.7914012,7280.17098 58.6569121,7279 C57.6062792,7279.04 56.3352055,7279.67099 55.5818643,7280.51498 C54.905374,7281.26397 54.3148354,7282.46095 54.4735932,7283.60894 C55.6455696,7283.69593 56.8418148,7283.03894 57.5708873,7282.19296 M60.1989864,7289.62485 C60.2283111,7292.65181 62.9696641,7293.65879 63,7293.67179 C62.9777537,7293.74279 62.562152,7295.10677 61.5560117,7296.51675 C60.6853718,7297.73474 59.7823735,7298.94772 58.3596204,7298.97372 C56.9621472,7298.99872 56.5121648,7298.17973 54.9134635,7298.17973 C53.3157735,7298.17973 52.8162425,7298.94772 51.4935978,7298.99872 C50.1203933,7299.04772 49.0738052,7297.68074 48.197098,7296.46676 C46.4032359,7293.98379 45.0330649,7289.44985 46.8734421,7286.3899 C47.7875635,7284.87092 49.4206455,7283.90793 51.1942837,7283.88393 C52.5422083,7283.85893 53.8153044,7284.75292 54.6394294,7284.75292 C55.4635543,7284.75292 57.0106846,7283.67793 58.6366882,7283.83593 C59.3172232,7283.86293 61.2283842,7284.09893 62.4549652,7285.8199 C62.355868,7285.8789 60.1747177,7287.09489 60.1989864,7289.62485"
                                        id="apple-[#173]"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              Apple
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-center items-center haveAcountBox">
                        <p className="mt-2 text-sm text-gray-600">
                          Already have an account?&nbsp;
                          <Link
                            to={`/${routePath.preloginPath}/${routePath.LoginPath}`}
                            className="text-blue-600 text_color font-medium"
                          >
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
