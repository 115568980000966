import React, { useState } from "react";
import * as apiEndpoint from "../../apis/postlogin/PostloginApis"
import { postRequest } from "../../interceptor/AxiosConfig";
import ErrorMessageHandling from "../../utilsfunction/ErrorHandling";
import Error from "../Alert/Error";
import Success from "../Alert/Success";
import FormError from "../Alert/FormError";
import CountryCodeAndFlag from "../../utilsfunction/CountryCodeAndFlag";

const ReferFriendModal = ({ referFriendModal, setReferFriendModal, toggleModal }) => {

    let countryCodeWithFlag = CountryCodeAndFlag();
    const { dial_code, flag } = countryCodeWithFlag[102];
    const [formData, setFormData] = useState({
        name: null,
        email: null,
        country_code: "+91",
        phone_number: null
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    // handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // form validation
    const validate = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = "Name is required";
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.phone_number) {
            formErrors.phone = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phone_number)) {
            formErrors.phone = "Phone number must be 10 digits";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    // submitting the refer a friend
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            await postRequest(`${apiEndpoint.referFriendApiEndpoint}`, formData)
                .then((response) => {
                    if (response.status === 200) {
                        setSuccessMessage(response.data.message);
                        setFormData({ name: "", email: "", phone: "" });
                        setReferFriendModal(false);
                    }
                }).catch((error) => {
                    // setErrorMessage(ErrorMessageHandling(error))
                })
        }
    };

    return (
        <>
            {errorMessage && <Error errorMessage={errorMessage} />}
            {successMessage && <Success successMessage={successMessage} />}
            {referFriendModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-90 z-40 flex justify-center items-center">
                    <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                        <h2 className="heading_text2 mb-4">Refer a Friend</h2>
                        <div className="flex flex-col gap-4">
                            {/* Name Field */}
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={`input_field mt-1 block w-full ${errors?.name
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    placeholder=" "
                                />
                                <span className="input_placeholder">Name</span>
                                {errors.name && (
                                    <FormError error={errors.name} />
                                )}
                            </div>

                            {/* Email Field */}
                            <div className="relative mb-4">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`input_field mt-1 block w-full ${errors?.email
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    placeholder=" "
                                />
                                <span className="input_placeholder">Email</span>
                                {errors.email && (
                                    <FormError error={errors.email} />
                                )}
                            </div>

                            {/* Phone Field */}
                            <div className="relative">
                                <div className="flex items-center">
                                    <button
                                        id="dropdown-phone-button"
                                        data-dropdown-toggle="dropdown-phone"
                                        className={` input_field py-4 px-4 flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white-100 border-r-0 border ${errors?.phone
                                            ? `form_error_border_code`
                                            : `border-gray-200`
                                            } rounded-s-lg`}
                                        type="button"
                                        disabled
                                    >
                                        {flag}&nbsp;{dial_code}
                                        <svg
                                            className="w-2.5 h-2.5 ms-2.5"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 10 6"
                                        >
                                            <path
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="m1 1 4 4 4-4"
                                            />
                                        </svg>
                                    </button>
                                    <div className="relative w-full">
                                        <input
                                            type="text"
                                            id="phone_number"
                                            name="phone_number"
                                            className={`input_field mt-1 block w-full ${errors?.phone
                                                ? `form_error_border`
                                                : `border-gray-200`
                                                }`}
                                            placeholder=" "
                                            aria-describedby="phone-error"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                        <span className="input_placeholder">Phone Number</span>
                                    </div>
                                </div>
                                {errors.phone && (
                                    <FormError error={errors.phone} />
                                )}
                            </div>
                            <div className="flex justify-between gap-2">
                                <button
                                    type="button"
                                    onClick={toggleModal}
                                    className="btn_secondary"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn_primary"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReferFriendModal;
