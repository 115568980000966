import React from 'react'

const FormError = ({ error }) => {
  return (
    <div>
      {error ? (
        <p className="form_error">{error}</p>
      ) : null}
    </div>
  )
}

export default FormError