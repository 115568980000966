import { createSlice } from "@reduxjs/toolkit";

const OFFER_INITIAL_STATE = {
    offerDetails: null,
}

const OfferSlice = createSlice({
    name: "offerDetails",
    initialState: OFFER_INITIAL_STATE,
    reducers: {
        setOfferDetails: (state, action) => {
            state.offerDetails = action.payload
        }
    }
})

export const { setOfferDetails } = OfferSlice.actions;
export default OfferSlice.reducer;