export const loginAPIEndpoint = "/auth/login";

export const verifyRegisterOTPAPIEndpoint = "/auth/verify-otp";

export const registerAPIEndpoint = "/auth/register";

export const forgotPasswordAPIEndpoint = "/auth/forgot-password";

export const verifyFPAPIEndpoint = "/auth/verify-forgot-password-otp";

export const resetPasswordAPIEndpoint = "/auth/reset-password";

export const resendOTPAPIEndpoint = "/auth/resend-otp";

export const socialSignupAPIEndpoint = "/auth/social-signup";

export const socialLoginAPIEndpoint = "/auth/social-login";

// homepage data

export const homePageDataApiEndpoint = "/customer/web/home-page";

// register and login with google api endpoint

export const registerWithGoogleAPIEndpoint = "/auth/social-signup"
export const loginWithGoogleAPIEndpoint = "/auth/social-login"
