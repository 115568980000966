/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import * as routePath from "../../routes";
import ErrorMessageHandling from "../../utilsfunction/ErrorHandling";
import FormError from "../../components/Alert/FormError";
import { useEffect, useState } from "react";
import { postRequest } from "../../interceptor/AxiosConfig";
import { resetPasswordAPIEndpoint } from "../../apis/prelogin/PreloginApis";
import Error from "../../components/Alert/Error";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../components/Reducers/MessageReducer";
import CloseIcon from "../../assets/icons/close.svg";
import EyeClose from "../../assets/prelogin/eye_close.svg";
import EyeOpen from "../../assets/prelogin/eye_open.svg";

import CompanyLogo from "../../assets/dospeedo.svg";
import VectorGrp from "../../assets/prelogin/register_graphic.png";
import { setCountryCode, setPhoneNumber } from "../../components/Reducers/UserReducer";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { phoneNumber, countryCode } = useSelector((state) => state?.userReducer);

  const initialState = {
    new_password: "",
    confirm_password: "",
  };

  const [formValues, setFormValues] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // api call for forgot password
  const resetPassword = async () => {
    await postRequest(`${resetPasswordAPIEndpoint}`, {
      ...formValues,
      country_code: countryCode,
      phone_number: phoneNumber,
    })
      .then((res) => {
        if (res.status === 200) {
          // setErrorMessage("");
          dispatch(setMessage(null));
          dispatch(setPhoneNumber(null))
          dispatch(setCountryCode(null))
          navigate(
            `/${routePath.preloginPath}/${routePath.PasswordResetSuccessPath}`
          );
        }
      })
      .catch((err) => {
        setErrorMessage(ErrorMessageHandling(err));
      });
  };

  //  setting the fields values
  const onHandleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // validating the fields
  const validate = (values) => {
    const errors = {};

    if (!values.new_password.trim()) {
      errors.new_password = "Password is required!";
    }
    if (!values.confirm_password.trim()) {
      errors.confirm_password = "Confirm Password is required!";
    } else if (values.new_password.trim() !== values.confirm_password.trim()) {
      errors.confirm_password = "Password and Confirm Password is not same!";
      errors.new_password = "Password and Confirm Password is not same!";
    }
    return errors;
  };

  // function for handling create button event
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    // if there is no errors login() function will be called
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      resetPassword();
    }
  }, [formErrors, isSubmit]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <section className="container mx-auto">
      <div className="relative grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 overflow-hidden py-24 registerOuter">
        <div className="registerInner">
          <div className="registerContent text-white">
            <div class="flex justify-center items-center mb-12">
              <img src={CompanyLogo} alt="Company Logo" />
            </div>
            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Mission
              </h2>
              <p>
                <strong>
                  “Simplifying cleaning and empowering lives with a click”
                </strong>
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Vision
              </h2>
              <p>
                Building a community where cleaning professionals are valued and
                empowered to make a difference by providing solution to cleaning
                challenges
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-10">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Services
              </h2>
              <ul>
                <li>Brooming and Mopping</li>
                <li>Residential Cleaning</li>
                <li>Utensils Cleaning</li>
                <li>Commercial Cleaning</li>
                <li>Kitchen Cleaning </li>
                <li>Full Home Cleaning</li>
                <li>Bathroom Cleaning</li>
                <li>Special Occasion Service</li>
              </ul>
            </div>
          </div>
          <div className="registerGrapic">
            <img src={VectorGrp} alt="Banner Graphic Img" />
          </div>
        </div>

        <div className="registerFormWrap">
          <div className="flex flex-col gap-0">
            <div className="mx-auto w-full login_heading">
              <div className="prelogin_heading_first flex justify-content-center align-items-center">
                <span>
                  <img
                    onClick={() => {
                      navigate(
                        `/${routePath.preloginPath}/${routePath.LoginPath}`
                      );
                    }}
                    className="cursor-pointer"
                    src={CloseIcon}
                    alt=""
                    width={"24px"}
                    height={"24px"}
                  />
                </span>
                <span className="">New Password</span>
              </div>
              <div className="formDescription">
                <p>
                  Securely update your password by entering it twice for
                  verification
                </p>
              </div>
            </div>
            <div className="registration_form_box resetPasswordBox w-full mt-4 mb-4 mx-auto bg-white border border-gray-200 shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
              <div className="p-4 pt-14">
                <div className="mt-0 pb-14">
                  <form>
                    <div className="formGrid grid gap-y-4">
                      <div>
                        <div className="relative">
                          <label htmlFor="">New Password</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="new_password"
                            className={`py-3 px-4 block w-full ${formErrors?.new_password
                              ? `form_error_border`
                              : `border-gray-200`
                              } rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none`}
                            required
                            placeholder="Password"
                            aria-describedby="password-error"
                            onChange={(e) => {
                              onHandleChange(e);
                            }}
                          />
                          {formValues?.new_password ? (
                            <div
                              className="absolute bottom-0 top-8 right-0 flex items-center px-2 cursor-pointer h-10"
                              onClick={handlePassword}
                            >
                              {showPassword ? (
                                <img src={EyeOpen} alt="Hide Password" />
                              ) : (
                                <img src={EyeClose} alt="See Password" />
                              )}
                            </div>
                          ) : null}
                          <FormError error={formErrors.new_password} />
                        </div>
                      </div>
                      <div>
                        <div className="relative">
                          <label htmlFor="">Confirm Password</label>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirm_password"
                            name="confirm_password"
                            className={`py-3 px-4 block w-full ${formErrors?.confirm_password
                              ? `form_error_border`
                              : `border-gray-200`
                              } rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none`}
                            required
                            placeholder="Confirm Password"
                            aria-describedby="confirm_password-error"
                            onChange={(e) => {
                              onHandleChange(e);
                            }}
                          />
                          {formValues?.confirm_password ? (
                            <div
                              className="absolute bottom-0 top-8 right-0 flex items-center px-2 cursor-pointer h-10"
                              onClick={handleConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <img src={EyeOpen} alt="Hide Password" />
                              ) : (
                                <img src={EyeClose} alt="See Password" />
                              )}
                            </div>
                          ) : null}
                          <FormError error={formErrors.confirm_password} />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="bg_button_color w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white disabled:opacity-50 disabled:pointer-events-none"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Update
                      </button>

                      {errorMessage ? (
                        <Error errorMessage={errorMessage} />
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
