// BookingCard.js
import React, { useState } from 'react';
import BookingDetailsModal from "../../../../components/modals/BookingDetailsModal";
import BookingReviewModal from "../../../../components/modals/BookingReviewModal";
import BookingCard from "../../../../components/Cards/BookingCard";
import { useNavigate } from "react-router-dom";
import * as routePath from "../../../../routes"

const CompletedBooking = ({ bookingDetails }) => {
    const navigate = useNavigate()
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [isReviewModalList, setReviewModalList] = useState(false);
    const [bookingDetail, setBookingDetail] = useState(null);

    const viewDetails = (booking) => {
        setReviewModalOpen(!isReviewModalOpen);
        setBookingDetail(booking)
    }

    const reviewModalList = () => {
        setReviewModalList(!isReviewModalList);
        setReviewModalOpen(!isReviewModalOpen);
    }

    const handleBookAgain = (id) => {
        navigate(`/${routePath.CustomerLayoutPath}/${routePath.addressPath}`, { state: { serviceId: id } })
    }

    return (
        <div className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-2`}>
            {/* Card Header */}
            {bookingDetails?.map((booking, index) => (
                <BookingCard
                    bookingDetails={booking}
                    viewDetails={() => viewDetails(booking)}
                    isReviewModalList={isReviewModalList}
                    setReviewModalList={setReviewModalList}
                />
            ))}
            {isReviewModalOpen && <BookingDetailsModal
                bookingDetails={bookingDetail}
                isReviewModalOpen={isReviewModalOpen}
                setReviewModalOpen={setReviewModalOpen}
                handleBookAgain={handleBookAgain}
            />
            }

            {isReviewModalList &&
                <BookingReviewModal
                    isReviewModalList={isReviewModalList}
                    setReviewModalList={setReviewModalList}
                    viewDetails={reviewModalList}

                />
            }
        </div>
    );
};

export default CompletedBooking;
