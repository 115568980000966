// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDtsqA2vsBsUSD-YJKLgnAPu8MHuE2Cpf0",
    authDomain: "dospeedo-9ed87.firebaseapp.com",
    projectId: "dospeedo-9ed87",
    storageBucket: "dospeedo-9ed87.appspot.com",
    messagingSenderId: "813709573467",
    appId: "1:813709573467:web:961b8d40b95c316adbae7e",
    measurementId: "G-2DM1DC0WDW"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// create an instance of the Apple provider
const appleProvider = new OAuthProvider('apple.com');

export { auth, provider, appleProvider };
