import React from 'react';
import CloseIcon from "../../assets/icons/closewhitebg.svg";
import RatingStar from "../../assets/booking/rating_star.svg"

const BookingReviewModal = ({ isReviewModalList, setReviewModalList }) => {

    return (
        <div>
            {isReviewModalList && (
                <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center modal_dialog">
                    <div className="bg-white rounded-lg w-96 shadow-lg max-h-[80vh] overflow-hidden flex flex-col">
                        <div className="flex justify-between items-center modal_header p-4">
                            <h2 className="head">Rating & Review</h2>
                            <button
                                onClick={() => setReviewModalList(!isReviewModalList)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <img src={CloseIcon} alt="" />
                            </button>
                        </div>

                        {/* Scrollable content */}
                        <div className="space-y-4 p-3 overflow-y-auto flex-grow modal_body">
                            <div className="flex items-start">
                                <img src="https://via.placeholder.com/44" alt="User" className="rounded-full" width={"44px"} height={"44px"} />
                                <div className="ml-4">
                                    <h3 className="reviewName">Sanjana Arya</h3>
                                    <p className="reviewService">Kitchen Cleaning</p>
                                </div>
                                <div className="ml-auto flex gap-2 items-center text-yellow-500">
                                    <span ><img src={RatingStar} alt="" /></span>
                                    <span className="ratingNum">4.2</span>
                                </div>
                            </div>
                            <p className="subText mt-1">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingReviewModal;
