/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const ServiceCard = ({
  Icon,
  ServiceHead,
  ServiceDescription1,
  ButtonTitle,
  onClickFn,
  name
}) => {
  return (
    <div className="card p-6 flex flex-col">
      <img src={Icon} alt={name} />
      <div className="service_body flex-1">
        <div className="service_head text-2xl font-bold text-gray-800 mb-4">
          {ServiceHead}
        </div>
        <div className="list_services py-2"
        // dangerouslySetInnerHTML={{ __html: ServiceDescription1 }}
        >
          <ul className="ul">
            {ServiceDescription1?.map((value) => {
              return (
                <li>
                  <div className="ellipseDot"></div>&nbsp;{value?.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="service_button cursor-pointer mt-6 mt-auto"
        onClick={onClickFn}
      >{ButtonTitle}</div>
    </div>
  );
};

export default ServiceCard;
