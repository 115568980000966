import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from "../../utilsfunction/Encrypt";

const localstorage = new LocalStorage()
const userLocation = localstorage.getItem("userLocation")
const initialState = {
    markerPosition: { lat: userLocation?.lat, lng: userLocation?.lon }
};

const geoLocation = createSlice({
    name: 'geoLocation',
    initialState,
    reducers: {
        setMarkerPosition: (state, action) => {
            state.markerPosition = action.payload;
        },
    },
});

export const { setMarkerPosition } = geoLocation.actions;

export default geoLocation.reducer;
