import { createSlice } from "@reduxjs/toolkit";

const USER_INITIAL_STATE = {
    userDetails: null,
    phoneNumber: null,
    countryCode: null,
}

const UserSlice = createSlice({
    name: "userDetails",
    initialState: USER_INITIAL_STATE,
    reducers: {
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        },
        setCountryCode: (state, action) => {
            state.countryCode = action.payload
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload
        }
    }
})

export const { setUserDetails, setCountryCode, setPhoneNumber } = UserSlice.actions;
export default UserSlice.reducer;