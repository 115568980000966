/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import AddAddressModal from "../../../../components/modals/AddAddressModal";
import Error from "../../../../components/Alert/Error";
import { getAddress } from "../../../../utilsfunction/GetApis";
import { postRequest } from "../../../../interceptor/AxiosConfig";
import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"
import * as routePath from "../../../../routes"
// import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Success from "../../../../components/Alert/Success";
// import { LocalStorage } from "../../../../utilsfunction/Encrypt";
import FormError from "../../../../components/Alert/FormError";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBookAgainAddress } from "../../../../components/Reducers/AddAddressReducer";
import useCountryState from "../../../../helper/geoLocationHook";
import { setMarkerPosition } from "../../../../components/Reducers/GeoLocationReducer";

const BookAgainAddressList = () => {
    const navigate = useNavigate()
    // const localstorage = new LocalStorage();
    const location = useLocation()

    const dispatch = useDispatch()
    let serviceId = location?.state?.serviceId
    // const userLocation = localstorage.getItem("userLocation")
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    // state for handle the address
    const [addressList, setAddressList] = useState(null)

    // State to store which checkbox is selected
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);

    // Function to handle checkbox selection
    const onHandleAddressCheck = (event, location) => {
        const checkboxValue = event.target.value;

        setSuccessMessage(false)
        setErrorMessage(false)

        if (selectedCheckbox === checkboxValue) {
            setSelectedCheckbox(null);
        } else {
            setSelectedCheckbox(checkboxValue);
            dispatch(setBookAgainAddress(location))

            // added later    
            navigate(`/${routePath.CustomerLayoutPath}/${routePath.servicesBookingPath}/${serviceId}`);
        }
    };

    useEffect(() => {
        getAddress(setAddressList, setErrorMessage)
    }, [])

    // get booking address
    const { bookAgainAddress } = useSelector((state) => state?.addressReducer);

    //  initial state for address

    const initialState = {
        location_name: null,
        address_line1: null,
        address_line2: null,
        block: null,
        house_number: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
        latitude: null,
        longitude: null,
        type: null,
    }

    const [addressValues, setAddressValues] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    // api for creating the address
    const createAddress = async () => {
        await postRequest(`${apiEndpoint.AddAddressAPIEndpoint}`, addressValues)
            .then((response) => {
                if (response.status === 201) {
                    setSuccessMessage(response.data.message)
                    setErrorMessage(false)
                    setShowModal(false)
                    getAddress(setAddressList, setErrorMessage)
                    setAddressValues(null)
                }
            }).catch((err) => {
                // setErrorMessage(ErrorMessageHandling(err))
                // setErrorMessage("")
                setSuccessMessage(false)
                setShowModal(false)
            })
    }

    // setting the fields values
    const onHandleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setAddressValues({ ...addressValues, [name]: value });
    };

    // validating the fields
    const validate = (values) => {
        const errors = {};
        if (!values.block) {
            errors.block = "Apartment/Road/Area is required!"
        }
        if (!values.house_number) {
            errors.house_number = "House/Flat number is required!"
        }
        if (!values.location_name) {
            errors.location_name = "Location is required!";
        }
        if (!values.type) {
            errors.type = "Address type is required!";
        }
        return errors;
    };

    // function for handling create button event
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(addressValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        // if there is no errors function will be called
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            createAddress();
        }
    }, [formErrors, isSubmit]);

    const { markerPosition } = useSelector((state) => state?.geoLocation)
    // Function to handle marker drag end
    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();

        // Update the marker's position in the state
        dispatch(setMarkerPosition({ lat: newLat, lng: newLng }));
    };

    const { userLocation } = useCountryState(markerPosition?.lat, markerPosition?.lng);

    const [closeLocationTitle, setCloseLocationTitle] = useState(true)

    useEffect(() => {
        if (userLocation) {
            setAddressValues({ ...addressValues, location: userLocation?.fullAddress, state: userLocation?.state });
        }
        if (!closeLocationTitle) {
            setCloseLocationTitle(!closeLocationTitle)
        }
    }, [userLocation]);

    useEffect(() => {
        getLatLng(bookAgainAddress?.location_name)
    }, [bookAgainAddress?.location_name])

    // const getLatLng = async (value) => {
    //     try {
    //         console.log('value',value);

    //         const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    //         const response = await getRequest(
    //             `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=${apiKey}`
    //         );

    //         if (response.data.status === 'OK') {
    //             const geoLocation = response.data.results[0].geometry.location;
    //             console.log('geoLocation',geoLocation)
    //             dispatch(setMarkerPosition({ lat: geoLocation.lat, lng: geoLocation.lng }));
    //             // setErrorMessage('');
    //         } else {
    //             // setErrorMessage('Location not found');
    //             // setErrorMessage("");
    //         }
    //     } catch (err) {
    //         console.log('Error fetching location data');

    //         // setErrorMessage('Error fetching location data');
    //         // setErrorMessage("");
    //     }
    // };

    const getLatLng = async (value) => {
        try {
            console.log('Fetching geolocation for:', value);
            const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
            const encodedAddress = encodeURIComponent(value);
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.status === 'OK') {
                const geoLocation = data.results[0].geometry.location;
                console.log('Geolocation:', geoLocation);

                // Update the marker position and map center
                const newPosition = { lat: geoLocation.lat, lng: geoLocation.lng };
                setMarkerPosition(newPosition);
                dispatch(setMarkerPosition(newPosition)); // Update Redux state if needed
            } else {
                console.error('Location not found:', data.status);
            }
        } catch (err) {
            console.error('Error fetching location data:', err);
        }
    };


    const dropdownRefAddressType = useRef(null);

    return (
        <section className="container mx-auto min-h-screen">
            <div className="pb-10 pt-6">
                <div className="px-4 ps-0 pb-4 breadcrump">
                    <div className="flex gap-2">
                        <span className="heading_first"> My Bookings</span>

                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.98027 6.9999C7.98027 7.25081 7.88447 7.50168 7.69327 7.69297L1.67362 13.7126C1.29069 14.0955 0.669847 14.0955 0.287077 13.7126C-0.0956924 13.3298 -0.0956924 12.7091 0.287077 12.3261L5.6136 6.9999L0.287263 1.67366C-0.0955064 1.29073 -0.0955064 0.670073 0.287263 0.287333C0.670033 -0.0957766 1.29088 -0.0957766 1.6738 0.287333L7.69346 6.30683C7.88469 6.49822 7.98027 6.74909 7.98027 6.9999Z" fill="#1B9075" />
                        </svg>

                        <span className="heading_second">Book again</span>
                    </div>
                </div>
                {errorMessage && <Error errorMessage={errorMessage} />}
                {successMessage && <Success successMessage={successMessage} />}
                {!selectedCheckbox && <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {/* Address Cards */}
                    {addressList?.map((address, index) => (
                        <div
                            key={index}
                            className="border cursor-pointer"
                        >
                            <div className="flex p-4 gap-4 items-center addressHeadBg">
                                <input type="checkbox" value={"address" + index} checked={selectedCheckbox === ("address" + index)} name={"address" + index} onChange={(e) => onHandleAddressCheck(e, address)} />
                                <h2 className="addressBoxHead">Address {index + 1}</h2>
                            </div>

                            <div className="flex flex-col gap-2 justify-center p-10 pt-4 pb-8 addressBodyBg">
                                <div className="flex flex-col gap-1">
                                    <p className="addresssub_head">Apartment/Road/Area</p>
                                    <p className="addresssub_text">{address.block}</p>
                                </div>
                                <div className="address_border"></div>
                                <div className="flex gap-2">
                                    <div className="flex flex-col gap-1">
                                        <p className="addresssub_head">House/Flat No</p>
                                        <p className="addresssub_text">{address.house_number}</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className="addresssub_head">Location's Name</p>
                                        <p className="addresssub_text">{address.location_name}</p>
                                    </div>
                                </div>
                                <div className="address_border"></div>
                                <div className="flex gap-2">
                                    <div className="flex flex-col gap-1">
                                        <p className="addresssub_head">Address Type</p>
                                        <p className="addresssub_text">{address.type}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Add New Address Button */}
                    <div
                        onClick={() => setShowModal(true)}
                        className="p-4 flex items-center justify-center cursor-pointer w-full addAddressBtn"
                    >
                        <div className="flex flex-col gap-2 justify-center items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.7999 10.8H13.2V1.19992C13.2 0.537669 12.6624 0 11.9999 0C11.3376 0 10.8 0.537669 10.8 1.19992V10.8H1.19992C0.537669 10.8 0 11.3376 0 11.9999C0 12.6624 0.537669 13.2 1.19992 13.2H10.8V22.7999C10.8 23.4623 11.3376 24 11.9999 24C12.6624 24 13.2 23.4623 13.2 22.7999V13.2H22.7999C23.4623 13.2 24 12.6624 24 11.9999C24 11.3376 23.4623 10.8 22.7999 10.8Z" fill="#1B9075" />
                            </svg>
                            <p className="addAddressBtnTxt">Add New Address</p>
                        </div>
                    </div>
                </div>}

                {selectedCheckbox && <div className="bg-white rounded-lg">
                    <section className="w-full bg-white rounded-lg p-8 pb-4 pick_location">
                        <div className="flex justify-between">
                            <div>
                                <span className="first_head">Pick Your </span><span className="second_head">Location</span>
                            </div>
                        </div>
                        <div className="mt-4 pick_location_body">
                            <div className="w-full rounded-lg shadow-sm h-72">
                                <Map
                                    style={{ borderRadius: '20px' }}
                                    defaultZoom={13}
                                    center={markerPosition} // Center the map on the marker position
                                    gestureHandling={'greedy'}
                                    disableDefaultUI
                                >
                                    <Marker
                                        position={markerPosition}
                                        draggable={true}
                                        onDragEnd={handleMarkerDragEnd} // Make sure to define this function if you want to handle dragging
                                    />
                                </Map>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 pick_location_fields">
                                <div className="relative">
                                    <input
                                        type="text"
                                        defaultValue={bookAgainAddress?.block ? bookAgainAddress?.block : ""}
                                        placeholder=" "
                                        className={`input_field mt-1 block w-full ${formErrors?.block
                                            ? `form_error_border`
                                            : `border-gray-200`
                                            }`}
                                        name="block"
                                        onChange={(e) => {
                                            onHandleChange(e);
                                        }}
                                        readOnly
                                    />
                                    <span className="input_placeholder input_placeholder_area">Apartment/Road/Area</span>
                                    <FormError error={formErrors.block} />
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        defaultValue={bookAgainAddress?.house_number ? bookAgainAddress?.house_number : ""}
                                        placeholder=" "
                                        className={`input_field mt-1 block w-full ${formErrors?.house_number
                                            ? `form_error_border`
                                            : `border-gray-200`
                                            }`}
                                        name="house_number"
                                        onChange={(e) => {
                                            onHandleChange(e);
                                        }}
                                        readOnly
                                    />
                                    <span className="input_placeholder input_placeholder_houseNumber">House/Flat No</span>
                                    <FormError error={formErrors.house_number} />
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        defaultValue={bookAgainAddress?.location_name ? bookAgainAddress?.location_name : ""}
                                        placeholder=" "
                                        className={`input_field mt-1 block w-full ${formErrors?.location_name
                                            ? `form_error_border`
                                            : `border-gray-200`
                                            }`}
                                        name="location_name"
                                        onChange={(e) => {
                                            onHandleChange(e);
                                            getLatLng(e.target.value)
                                        }}
                                        readOnly
                                    />
                                    <span className="input_placeholder input_placeholder_location">Location{"'"}s Name</span>
                                    <FormError error={formErrors.location_name} />
                                </div>
                                <div className="relative w-full" ref={dropdownRefAddressType}>
                                    <input
                                        type="text"
                                        className={`input_field mt-1 block w-full select_quantity ${formErrors?.type
                                            ? `form_error_border`
                                            : `border-gray-200`
                                            }`}
                                        placeholder=" "
                                        value={bookAgainAddress?.type ? bookAgainAddress?.type : ""}
                                        // onFocus={handleAddressTypeSelect}
                                        readOnly
                                    />
                                    <span className="input_placeholder input_placeholder_addresstype">Address Type</span>
                                    {/* <FormError error={formErrors.type} />
                                    {isAddressTypeVisible && (
                                        <div className="absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                                            <ul className="py-2">
                                                <li
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleAddressTypeClick("Home")}
                                                >
                                                    {"Home"}
                                                </li>
                                                <li
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleAddressTypeClick("Work")}
                                                >
                                                    {"Work"}
                                                </li>
                                                <li
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleAddressTypeClick("Friends and family")}
                                                >
                                                    {"Friends and family"}
                                                </li>
                                                <li
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleAddressTypeClick("Other")}
                                                >
                                                    {"Other"}
                                                </li>
                                            </ul>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Action Buttons */}
                    <div className="flex flex-col md:flex-row justify-between p-8 pt-0 w-full">
                        <div className="flex gap-2 items-center w-6/12">
                            <input type="checkbox" name="" value="" />
                            <span className="subtext">Save address for smooth process</span>
                        </div>
                        <div className="w-full lg:w-4/12 flex gap-3 py-6 justify-end service_buttons w-4/12">
                            <button className="btn_secondary" onClick={() => { setSelectedCheckbox(false); dispatch(setBookAgainAddress(null)) }}>Cancel</button>
                            <button className="btn_primary" onClick={() => navigate(`/${routePath.CustomerLayoutPath}/${routePath.servicesBookingPath}/${serviceId}`)}>Confirm Address</button>
                        </div>
                    </div>
                </div>}

                {/* Modal */}
                <AddAddressModal
                    show={showModal}
                    setShow={setShowModal}
                    onClickFn={handleSubmit}
                    onChangeFn={onHandleChange}
                    formErrors={formErrors}
                    userLocation={userLocation}
                    addressValues={addressValues}
                    setAddressValues={setAddressValues}
                    setAddressList={setAddressList}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                    setShowModal={setShowModal}
                />
            </div>
        </section>
    );
};

export default BookAgainAddressList;