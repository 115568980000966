import React from "react";
import DateIcon from "../../assets/blog/date_icon.svg";

const BlogCard = ({ date, heading, blogImg, onClickFn }) => {
  return (
    <div className="blog_card bg-white p-6 cursor-pointer"
      onClick={onClickFn}
    >
      <img
        src={blogImg}
        alt={heading}
        className="w-full object-cover rounded-md mb-4"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-xl font-bold text-gray-800 mb-2">{heading}</h3>
        <div className="flex mt-2">
          <img src={DateIcon} alt={"Date"} /> &nbsp;
          <p className="text-gray-600">{date}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
