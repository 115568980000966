import React, { useEffect, useState } from 'react'
import { getRequest } from "../../../../interceptor/AxiosConfig"
import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Error from "../../../../components/Alert/Error";

import Header from "../../../../components/Header/index"
import Footer from "../../../../components/Footer/Footer"
import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"

const TermsConditions = () => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [TermsConditions, setTermsConditions] = useState(null)

    const getTermsConditions = async () => {
        await getRequest(`${apiEndpoint.tcPPAPIEndpoint}`).then((res) => {
            if (res.status === 200) {
                setTermsConditions(res.data.data["terms-and-conditions"])
            }
        }).catch((err) => {
            // setErrorMessage(ErrorMessageHandling(err))
        });
    }

    useEffect(() => {
        getTermsConditions()
    }, [])

    return (
        <div>
            {errorMessage && <Error errorMessage={errorMessage} />}

            <Header />
            <div className="container mx-auto min-h-screen bg-gray-100 py-8">
                <div className="w-full">
                    <div className="col-span-2 bg-white p-6 rounded-lg shadow">
                        <div className="flex justify-center pb-6">
                            <h1 className="uppercase text_first">{TermsConditions?.title}</h1>
                        </div>
                        <div className="text-gray-800 leading-relaxed">

                            <p className="mb-4" dangerouslySetInnerHTML={{ __html: TermsConditions?.description }}>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TermsConditions