const ErrorMessageHandling = (error) => {
  // pass the error from catch block in api call
  if (error.response.status === 406) {
    return error.response.data.message;
  } else if (error.response.status === 401) {
    return error.response.data.message;
  }
  else if (
    error.response.status === 500 ||
    error.response.status === 502 ||
    error.response.status === 404 ||
    error.response.status === 403
  ) {
    return error.response.data.message || "Something went wrong please try again later";
  } else {
    if (typeof error.response.data.message == "string") {
      return error.response.data.message;
    } else {
      //getting the keys from message in error response
      let res = Object.keys(error.response.data.message);
      const errors = {};
      // loop over the keys and filter out errors with key and value and store in errors object
      res.map((v, _) => {
        errors[`${v}`] = error.response.data.message[`${v}`];
        return errors;
      });
      // loop over errors object and pass each error to show error component
      Object.keys(errors).map((key, _) => {
        let temp = errors[`${key}`][0];
        return temp;
      });
    }
  }
};

export default ErrorMessageHandling;


export const SocialSignInErrorHandling = (error) => {

  const errorCode = error.code;

  switch (errorCode) {
    case 'auth/popup-closed-by-user':
      return 'The login popup was closed before completing sign in. Please try again.';
    case 'auth/network-request-failed':
      return 'Network error occurred. Please check your internet connection and try again.';
    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email but different sign-in credentials. Try logging in with a different method.';
    case 'auth/credential-already-in-use':
      return 'This credential is already associated with a different user account.';
    case 'auth/cancelled-popup-request':
      return 'Another popup request was made. Please wait and try again.';
    default:
      return 'An unexpected error occurred. Please try again later.';
  }
};
