import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./components/Reducers/UserReducer";
import MessageReducer from "./components/Reducers/MessageReducer";
import NotificationReducer from "./components/Reducers/NotificationReducer";
import AddAddressReducer from "./components/Reducers/AddAddressReducer";
import OfferReducer from "./components/Reducers/OfferReducer";
import PriceReducer from "./components/Reducers/Price";
import GeoLocationReducer from "./components/Reducers/GeoLocationReducer";

export const store = configureStore({
    reducer: {
        userReducer: UserReducer,
        messageReducer: MessageReducer,
        notifications: NotificationReducer,
        addressReducer: AddAddressReducer,
        offerReducer: OfferReducer,
        priceReducer: PriceReducer,
        geoLocation: GeoLocationReducer,
    },
})