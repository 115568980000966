import { createSlice } from "@reduxjs/toolkit";

const MESSAGE_INITIAL_STATE = {
    message: "",
}

const MessageSlice = createSlice({
    name: "message",
    initialState: MESSAGE_INITIAL_STATE,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload
        }
    }
})

export const { setMessage } = MessageSlice.actions;
export default MessageSlice.reducer;