/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Calendar from "react-calendar";

// import css
import "react-calendar/dist/Calendar.css";
import "../../../../styles/sidebar.css";

// import images and icons
import DoSpeedoLogo from "../../../../assets/dospeedo.svg";
import PieIcon from "../../../../assets/icons/pie.svg";
import CleanIcon from "../../../../assets/icons/clean.svg";
import SearchIcon from "../../../../assets/icons/search.svg";
import ProfileIcon from "../../../../assets/icons/profile.svg";
import LeavesIcon from "../../../../assets/icons/leaves.svg";
import SettingIcon from "../../../../assets/icons/setting.svg";
import LogoutIcon from "../../../../assets/icons/logout.svg";

import Closeicon from "../../../../assets/icons/close.svg";

const Sidebar = () => {
  // function for showing the sidebar
  const sidebar = document.querySelector(".sidebar_main");
  function toggleSidebar() {
    sidebar.classList.toggle("active");
  }
  return (
    <div className="sidebar_main bg-gray-800 w-full lg:w-1/5 text-white min-h-screen p-4">
      <div className="sidebar_content">
        <img
          width="20"
          height="20"
          className="close_icon cursor-pointer"
          src={Closeicon}
          alt={"Close"}
          onClick={toggleSidebar}
        />
        <img src={DoSpeedoLogo} alt="Logo" className="company_logo" />
        <div className="sidebar_frame">
          <ul className="links">
            <li className="active mb-4">
              <img src={PieIcon} alt="Home" />
              <a href="#" className="">
                Home
              </a>
            </li>
            <li className="mb-4">
              <img src={CleanIcon} alt="MyJobs" />
              <a href="#" className="">
                My Jobs
              </a>
            </li>
            <li className="mb-4">
              <img src={PieIcon} alt="Payment" />
              <a href="#" className="">
                Payments
              </a>
            </li>
            <li className="mb-4">
              <img src={SearchIcon} alt="Search" />
              <a href="#" className="">
                Search
              </a>
            </li>
            <li className="mb-4">
              <img src={ProfileIcon} alt="Profile" />
              <a href="#" className="">
                Profile
              </a>
            </li>
            <li className="mb-4">
              <img src={LeavesIcon} alt="Leaves" />
              <a href="#" className="">
                Leaves
              </a>
            </li>
            <li className="mb-4">
              <img src={SettingIcon} alt="Settings" />
              <a href="#" className="">
                Settings
              </a>
            </li>
            <li className="mb-4">
              <img src={LogoutIcon} alt="Logout" />
              <a href="#" className="">
                Logout
              </a>
            </li>
          </ul>
          <div className="calendar">
            {/* <h3 className="text-lg font-bold">September 2021</h3>
                <div className="grid grid-cols-7 gap-2 mt-2">
                    {[...Array(30).keys()].map(day => (
                        <div key={day} className="text-center">{day + 1}</div>
                    ))}
                </div> */}
            <Calendar
              className={"calendar_main"}
              tileClassName={"calendar_dates"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
