import ReactGA from "react-ga4";

export const initGA = () => {
  const GA_ID = 'G-2DM1DC0WDW'; // Use environment variable
  ReactGA.initialize(GA_ID);
};

export const trackPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};

export const trackEvent = (action, category, label) => {
  ReactGA.event({
    action,
    category,
    label,
  });
};
