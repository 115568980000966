import { Map, Marker } from "@vis.gl/react-google-maps"
import React from 'react'

const TrackModal = ({ show, setShow, userLocation, agentLocation }) => {

    return (
        <div className={`fixed overflow-scroll inset-0 md:px-20 flex items-center justify-center bg-black bg-opacity-90 ${show ? 'block' : 'hidden'}`}>
            <div className="bg-white p-4 rounded-lg shadow-lg w-full">

                <section className="w-full bg-white rounded-lg p-6 pb-4 pick_location">
                    <div className="flex justify-between">
                        <div>
                            <span className="first_head">Maid </span><span className="second_head">Location</span>
                        </div>
                    </div>
                    <div className="mt-4 pick_location_body">
                        <div className="w-full rounded-lg shadow-sm h-72">
                            <Map
                                style={{ borderRadius: "20px" }}
                                defaultZoom={13}
                                defaultCenter={{ lat: userLocation?.lat, lng: userLocation?.lon }}
                                gestureHandling={"greedy"}
                                disableDefaultUI
                            >
                                {/* marker for user location */}
                                <Marker position={{ lat: userLocation?.lat, lng: userLocation?.lon }} draggable={true} />

                                {/* marker for agent location */}
                                <Marker position={agentLocation} draggable={false} icon={"https://maps.google.com/mapfiles/ms/icons/blue-dot.png"} />
                            </Map>
                        </div>
                    </div>
                </section>
                {/* Action Buttons */}
                <div className="flex flex-col md:flex-row justify-between p-6 pt-0">
                    <div className="flex gap-2 items-center w-full">
                    </div>
                    <div className="w-full lg:w-4/12 flex gap-3 py-6 justify-end service_buttons">
                        <button className="btn_secondary cancel" onClick={() => setShow(!show)}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackModal;
