// testing for valid email through regex pattern
export function isValidEmail(email) {
  return /^([\w.%+-]+)@([\w-]+\.)+([\w+]{2,})$/i.test(email);
}

// validating the otp digits only
export function isValidOtpDigit(otp) {
  return /^[0-9]{6,6}$/i.test(otp);
}

// validating the phone number

export function isValidPhoneNumber(mobile) {
  return /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(mobile);
}