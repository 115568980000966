import { Outlet, Navigate } from "react-router-dom";
import { LocalStorage } from "../utilsfunction/Encrypt";
import * as routePath from "../routes";

const PrivateRoutes = () => {
  const localstorage = new LocalStorage();

  let auth = localstorage.getItem("1");
  if (!auth) {
    return <Navigate to={`/${routePath.preloginPath}/${routePath.LoginPath}`} />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoutes;
