import React from 'react';
import CloseIcon from "../../assets/icons/close.svg"

const ServiceModal = ({ isOpen, onClose, children, ServiceHead }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90 modal_dialog">
            <div className="bg-white w-96 modal_content ">
                <div className="flex justify-between items-center gap-2 p-4 modal_header">
                    <h2 className="head">{ServiceHead}</h2>
                    <button
                        onClick={onClose}
                    >
                        <img src={CloseIcon} alt="close" width="24px" height="24px" />
                    </button>
                </div>

                {children}
            </div>
        </div>
    );
};

export default ServiceModal;
