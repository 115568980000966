
export const preloginPath = "dospeedo"
export const LoginPath = "login";

export const RegistrationPath = "registration";
export const OTPVerificationPath = "otp-verification";
export const ForgotPasswordPath = "forgot-password";
export const RegistrationSuccessPath = "registration-complete";
export const PasswordResetSuccessPath = "password-reset-complete";
export const PasswordResetPath = "password-reset";

// paths for user after login
export const homePath = "home";
export const dashboard = "dashboard";

// paths after login
export const CustomerLayoutPath = "user";

// services for user after login
export const servicesListPath = "services";

export const servicesBookingPath = "services-booking";


// booking routes path

export const bookingListPath = "my-booking"

export const cancelledBookingPath = "cancelled-cancelled"

// profile path

export const profilePath = "user-profile"

export const addressAddEditPath = "user-address"

// review path

export const reviewPath = "add-review"

// address path
export const addressPath = "add-address"

// blog details path

export const blogDetailsPath = "blog/blog-details"

// about us path
export const aboutUsPath = "about-us"

// contact us path
export const contactUsPath = "contact-us"

// terms and conditions and privacy policy path
export const termsAndConditionsPath = "terms-and-conditions"
export const privacyPolicyPath = "privacy-policy"
export const refundPolicy = "refund-policy"

// subscription path
export const subscriptionPath = "subscriptions-plans"

export const myMembershipPlansPath = "my-membership-plans"
