// Import the CryptoJS library for encryption and decryption.
import CryptoJS from "crypto-js";

// Custom class for handling encrypted session storage.
export class LocalStorage {
  constructor() {
    // The secret key used for encryption and decryption.
    this.secrete_key = "DOspeedo@_2024";
  }
  // Method to remove an item from encrypted session storage.
  removeItem(key) {
    // Retrieve the encrypted session storage data using a predefined key.
    let encryptedValue = localStorage.getItem("doSpeedo");
    if (encryptedValue) {
      // Decrypt the encrypted data using the secret key and convert to JSON.
      let bytes = CryptoJS.AES.decrypt(encryptedValue, this.secrete_key);
      let decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // If the key exists, delete it from the decrypted data.
      if (decryptedValue.hasOwnProperty(key)) {
        delete decryptedValue[key];
        // Encrypt the modified data and update it in the session storage.
        let encryptedValue = CryptoJS.AES.encrypt(
          JSON.stringify(decryptedValue),
          this.secrete_key
        ).toString();
        localStorage.setItem("dospeedo", encryptedValue);
      }
    }
  }

  // Method to get an item from encrypted session storage.
  getItem(key) {
    // Retrieve the encrypted session storage data using a predefined key.
    let encryptedValue = localStorage.getItem("dospeedo");
    if (encryptedValue) {
      // Decrypt the encrypted data using the secret key and convert to JSON.
      let bytes = CryptoJS.AES.decrypt(encryptedValue, this.secrete_key);
      let decrypted_value = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // Return the value associated with the provided key.
      return decrypted_value[key];
    } else {
      return null;
    }
  }

  // Method to set an item in encrypted session storage.
  setItem = (key, value) => {
    // Retrieve the encrypted session storage data using a predefined key.
    let encryptedValue = localStorage.getItem("dospeedo");
    if (encryptedValue) {
      // Decrypt the encrypted data using the secret key and convert to JSON.
      let bytes = CryptoJS.AES.decrypt(encryptedValue, this.secrete_key);
      let decrypted_value = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // Set the new value associated with the provided key.
      decrypted_value[key] = value;
      // Encrypt the modified data and update it in the session storage.
      let encrypted_value = CryptoJS.AES.encrypt(
        JSON.stringify(decrypted_value),
        this.secrete_key
      ).toString();
      localStorage.setItem("dospeedo", encrypted_value);
    } else {
      // If there's no existing data in session storage, create a new data object.
      let sessionData = {};
      // Set the new value associated with the provided key.
      sessionData[key] = value;
      // Encrypt the new data.
      let encrypted_value = CryptoJS.AES.encrypt(
        JSON.stringify(sessionData),
        this.secrete_key
      ).toString();
      // Set the encrypted data in session storage using a predefined key.
      localStorage.setItem("dospeedo", encrypted_value);
    }
  };
}