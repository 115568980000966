/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// import FormError from "../Alert/FormError";
import { Map, Marker } from "@vis.gl/react-google-maps";
import "../../styles/postlogin/services.css"
import FormError from "../Alert/FormError";
import { useDispatch, useSelector } from "react-redux";
import { setMarkerPosition } from "../Reducers/GeoLocationReducer";
import useCountryState from "../../helper/geoLocationHook";
import { getRequest } from "../../interceptor/AxiosConfig";

// Modal Component for adding a new address
const EditAddressModal = ({ show, setShow, onClickFn, onChangeFn, formErrors, setErrorMessage, address, addressValues, setAddressValues }) => {
    const dispatch = useDispatch()

    const dropdownRefAddressType = useRef(null);

    const [isAddressTypeVisible, setAddressTypeVisible] = useState(false);
    const [addressTypeOption, setAddressTypeOption] = useState(null);

    const handleAddressTypeClick = (option) => {
        setAddressValues({ ...addressValues, "type": option });
        setAddressTypeOption(option);
        setAddressTypeVisible(false);
    };

    // function to handle clicking outside of the input and dropdown
    const handleClickOutside = (event) => {
        if (dropdownRefAddressType.current && !dropdownRefAddressType.current.contains(event.target)) {
            setAddressTypeVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleAddressTypeSelect = () => {
        setAddressTypeVisible(true)
    }

    const { markerPosition } = useSelector((state) => state?.geoLocation)
    // Function to handle marker drag end
    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();

        // Update the marker's position in the state
        dispatch(setMarkerPosition({ lat: newLat, lng: newLng }));
    };

    const { userLocation } = useCountryState(markerPosition?.lat, markerPosition?.lng);

    const [closeLocationTitle, setCloseLocationTitle] = useState(true)

    useEffect(() => {
        if (userLocation) {
            setAddressValues({ ...addressValues, location_name: userLocation?.fullAddress, state: userLocation?.state });
        }
        if (!closeLocationTitle) {
            setCloseLocationTitle(!closeLocationTitle)
        }
    }, [userLocation]);

    const getLatLng = async (value) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
            const response = await getRequest(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=${apiKey}`
            );

            if (response.data.status === 'OK') {
                const location = response.data.results[0].geometry.location;

                dispatch(setMarkerPosition({ lat: location.lat, lng: location.lng }));
                // setErrorMessage('');
            } else {
                // setErrorMessage('Location not found');
                // setErrorMessage("");
            }
        } catch (err) {
            // setErrorMessage('Error fetching location data');
            // setErrorMessage("");
        }
    };

    return (
        <div className={`fixed overflow-scroll inset-0 md:px-20 flex items-center justify-center bg-black bg-opacity-90 ${show ? 'block' : 'hidden'}`}>
            <div className="bg-white p-4 rounded-lg shadow-lg w-full">

                <section className="w-full bg-white rounded-lg p-6 pb-4 pick_location">
                    <div className="flex justify-between">
                        <div>
                            <span className="first_head">Edit </span><span className="second_head">Address</span>
                        </div>
                    </div>
                    <div className="mt-4 pick_location_body">
                        <div className="w-full rounded-lg shadow-sm h-72">
                            <Map
                                style={{ borderRadius: "20px" }}
                                defaultZoom={13}
                                defaultCenter={markerPosition}
                                gestureHandling={"greedy"}
                                disableDefaultUI
                            >
                                <Marker
                                    position={markerPosition}
                                    draggable={true}
                                    onDragEnd={handleMarkerDragEnd}
                                />
                                {closeLocationTitle && <div
                                    className={`locationDetails`}
                                >
                                    <div className="flex gap-4 p-4">
                                        <div>
                                            <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.68314 8.34651C8.60179 8.34651 9.34651 7.60179 9.34651 6.68314C9.34651 5.76449 8.60179 5.01978 7.68314 5.01978C6.76449 5.01978 6.01978 5.76449 6.01978 6.68314C6.01978 7.60179 6.76449 8.34651 7.68314 8.34651Z" stroke="#F3A8A2" stroke-width="1.38614" stroke-miterlimit="10" />
                                                <path d="M12.6039 6.68297C12.6039 3.91069 10.3861 1.69287 7.61385 1.69287C4.84157 1.69287 2.69305 3.91069 2.69305 6.68297C2.69305 8.27703 3.52474 9.52455 4.56434 10.5642C5.46533 11.4651 7.68315 13.9602 7.68315 13.9602C7.68315 13.9602 9.83167 11.6038 10.802 10.5642C11.7723 9.52455 12.6039 8.20772 12.6039 6.68297Z" stroke="#1B9075" stroke-width="1.38614" stroke-miterlimit="10" />
                                            </svg>
                                        </div>
                                        <div>
                                            {userLocation?.fullAddress}
                                        </div>
                                        <div className="cursor-pointer" onClick={() => setCloseLocationTitle(!closeLocationTitle)}>
                                            <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 2L14 14" stroke="#B8B8D2" stroke-width="1.5" />
                                                <path d="M14 2L2 14" stroke="#B8B8D2" stroke-width="1.5" />
                                            </svg>

                                        </div>

                                    </div>
                                </div>}
                            </Map>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 pick_location_fields">
                            <div className="relative">
                                <input
                                    type="text"
                                    defaultValue={addressValues?.block ? addressValues?.block : ""}
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.block
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    name="block"
                                    onChange={(e) => {
                                        onChangeFn(e);
                                    }}
                                />
                                <span className="input_placeholder input_placeholder_area">Apartment/Road/Area</span>
                                <FormError error={formErrors.block} />
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    defaultValue={addressValues?.house_number ? addressValues?.house_number : ""}
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.house_number
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    name="house_number"
                                    onChange={(e) => {
                                        onChangeFn(e);
                                        getLatLng(e.target.value)
                                    }}
                                />
                                <span className="input_placeholder input_placeholder_houseNumber">House/Flat No</span>
                                <FormError error={formErrors.house_number} />
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    defaultValue={addressValues?.location_name ? addressValues?.location_name : ""}
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.location_name
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    name="location_name"
                                    onChange={(e) => {
                                        onChangeFn(e);
                                    }}
                                    readOnly
                                />
                                <span className="input_placeholder input_placeholder_location">Location{"'"}s Name</span>
                                <FormError error={formErrors.location_name} />
                            </div>

                            <div className="relative w-full" ref={dropdownRefAddressType}>
                                <input
                                    type="text"
                                    className={`input_field mt-1 block w-full select_quantity ${formErrors?.type
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    placeholder=" "
                                    name="type"
                                    value={addressValues?.type ? addressValues?.type : addressTypeOption}
                                    onFocus={handleAddressTypeSelect}
                                />
                                <span className="input_placeholder input_placeholder_addresstype">Address Type</span>
                                <FormError error={formErrors.type} />
                                {isAddressTypeVisible && (
                                    <div className="absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                                        <ul className="py-2">
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Home")}
                                            >
                                                {"Home"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Work")}
                                            >
                                                {"Work"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Friends and family")}
                                            >
                                                {"Friends and family"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Other")}
                                            >
                                                {"Other"}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                {/* Action Buttons */}
                <div className="flex flex-col md:flex-row justify-between p-6 pt-0">
                    <div className="flex gap-2 items-center w-full">
                        <input type="checkbox" name="" value="" />
                        <span className="subtext">Save address for smooth process</span>
                    </div>
                    <div className="w-full lg:w-4/12 flex gap-3 py-6 justify-end service_buttons">
                        <button className="btn_secondary cancel" onClick={() => setShow(!show)}>Cancel</button>
                        <button className="btn_primary next" onClick={() => onClickFn(address?.id)}>Confirm Address</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAddressModal