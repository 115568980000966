/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ServiceModal from "../modals/ServiceListModal";

const ServiceCard = ({
  ServiceImg,
  ServiceHead,
  ServiceDescription,
  ButtonTitle,
  servicePrice,
  bookingServices
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Function to extract plain text from HTML
  const extractTextFromHTML = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html; // Set the HTML
    return tempElement.textContent || tempElement.innerText || ""; // Extract text
  };

  // Get the text from the HTML description
  const fullDescription = extractTextFromHTML(ServiceDescription);
  
  // Limit the description to the first 20 to 30 characters
  const descriptionLine = fullDescription.length > 30 
    ? fullDescription.slice(0, 30) + "..." // Truncate and add ellipsis
    : fullDescription; // Use the full text if it's shorter than 30 characters

  return (
    <div className="service_card p-6">
      <div className="service_body">
        <div className="serviceImageWrap relative">
          {/* <img src={ServiceImg} alt={ServiceHead} className="serviceImg" /> */}
          {ServiceImg && ServiceImg !== "https://api.dospeedo.com/uploads/null" ? ( // Check if the image is valid
            <img src={ServiceImg} alt={ServiceHead} className="serviceImg" />
          ) : (
            <div className="w-full h-56 bg-gray-200 flex items-center justify-center rounded-md">
              <span className="text-gray-500">No Image</span>
            </div>
          )}
          <div className="service_price">
            <span>{"Rs "}{servicePrice}</span>
          </div>
        </div>
        <div className="service_head text-2xl font-bold text-gray-800 mb-4" title={ServiceHead}>
          {ServiceHead}
        </div>
        <div className="flex items-center justify-between">
          <div className="service_description py-2">
            {/* NEW: Display the extracted text here */}
            <span>{descriptionLine}</span> 
          </div>
          <div className="services_desc_view cursor-pointer" onClick={openModal}>View more</div>
          <ServiceModal isOpen={isModalOpen} onClose={closeModal} ServiceHead={ServiceHead}>
            <div className="list_services p-2" dangerouslySetInnerHTML={{ __html: ServiceDescription }}>
            </div>
          </ServiceModal>
        </div>
      </div>
      <button className="service_button mt-6" onClick={bookingServices}>{ButtonTitle}</button>
    </div>
  );
};

export default ServiceCard;
