import React, { useEffect, useState } from "react";
import Sidebar from "../Layout/Sidebar";
import Footer from "../Layout/Footer";
import Header from "./Header";
import "../../../../styles/dashboard.css";
import TimerIcon from "../../../../assets/icons/timer.svg";
import DotIcon from "../../../../assets/icons/dot.svg";
import CompleteIcon from "../../../../assets/icons/complete.svg";

import EllipseComplete from "../../../../assets/icons/ellipseComplete.svg";
import EllipseComplete1 from "../../../../assets/icons/ellipseComplete1.svg";
import EllipseComplete2 from "../../../../assets/icons/ellipseComplete2.svg";
import EllipseComplete3 from "../../../../assets/icons/ellipseComplete3.svg";
import RatingIcon from "../../../../assets/icons/rating.svg";

// week and month glance icons
import InfoIcon from "../../../../assets/icons/GlanceIcon/infoIcon.svg";
import TagIcon from "../../../../assets/icons/GlanceIcon/tagIcon.svg";
import UserAddIcon from "../../../../assets/icons/GlanceIcon/userAdd.svg";

const Dashboard = () => {
  const currentYear = new Date().getFullYear();

  const [upcomingJob, setUpcomingJob] = useState(null);
  const [income, setIncome] = useState(null);

  useEffect(() => {
    setUpcomingJob([
      {
        job_time: "20 Mar, 13:00",
        job_description: "Clean Furniture",
        job_address: "30 Rose Av., Mr. Thatcher",
      },
      {
        job_time: "20 Mar, 13:00",
        job_description: "Clean Furniture",
        job_address: "30 Rose Av., Mr. Thatcher",
      },
      {
        job_time: "20 Mar, 13:00",
        job_description: "Clean Furniture",
        job_address: "30 Rose Av., Mr. Thatcher",
      },
    ]);

    setIncome([
      {
        sign: "$",
        income_amount: "32248",
        income_decription: "Total Amount",
        icon: EllipseComplete,
      },
      {
        sign: "$",
        income_amount: "1800",
        income_decription: "This Month",
        icon: EllipseComplete1,
      },
      {
        sign: "$",
        income_amount: "248",
        income_decription: "Bonus Tips",
        icon: EllipseComplete2,
      },
      {
        sign: "$",
        income_amount: "378",
        income_decription: "Tips",
        icon: EllipseComplete3,
      },
    ]);
  }, []);

  return (
    <div className="flex lg:flex-row">
      <Sidebar />
      <div className="flex-1 dashboard">
        <Header />

        <div className="flex-1 p-6 bg-gray-100 dashboard_main">
          <div className="grid gap-6">
            <div className="grid sm:grid-cols-1 md:grid-cols-[28%_28%_40%] lg:grid-cols-[28%_28%_40%] gap-6">
              {/* Current Job Card */}
              <div className="current_job_card">
                <div className="content">
                  <div className="content_heading">
                    <div className="heading_name">
                      <h2 className="heading">Current Job</h2>
                      <p className="para_text_left">12:00PM - 14:00 PM</p>
                    </div>
                    <div className="time_address">
                      <p className="para_text_right">Mr. Thatcher</p>
                      <p className="para_text_right">30 Rose Av.</p>
                    </div>
                  </div>
                  <div className="countdown-circle">
                    <img src={TimerIcon} alt="" />
                    <div className="remaining_time">
                      <div className="time">0h: 40m</div>
                      <div className="text">Remaining</div>
                    </div>
                    <div className="pointer">
                      <img src={DotIcon} alt="" className="pointer_img" />
                    </div>
                  </div>
                </div>
              </div>
              {/* Available Balance Card */}
              <div className="available_balance_card">
                <div className="content">
                  <div className="content_heading">
                    <div className="heading_name">
                      <h2 className="heading">Available Balance</h2>
                      <p className="para_text_left">As on 20th March 2024</p>
                    </div>
                  </div>
                  <div className="complete-circle">
                    <img src={CompleteIcon} alt="" />
                    <div className="total_amount">
                      <div className="amount">
                        $<span>1235</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Upcoming Jobs Card */}
              <div className="bg-white p-6 rounded-lg shadow-md upcoming_job">
                <h2 className="text-xl font-bold upcoming_job_head">
                  Upcoming Jobs
                </h2>
                {upcomingJob &&
                  Object.keys(upcomingJob)?.map((value) => {
                    return (
                      <div className="upcoming_job_block">
                        <p className="upcoming_job_time">
                          {upcomingJob[value].job_time}
                        </p>
                        <p className="upcoming_job_description">
                          {upcomingJob[value].job_description}
                        </p>
                        <p className="upcoming_job_address">
                          {upcomingJob[value].job_address}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="grid sm:grid-cols-1 md:grid-cols-[58%_40%] lg:grid-cols-[58%_40%] gap-6">
              {/* Income Card */}
              <div className="bg-white p-6 rounded-lg shadow-md income">
                <div className="income_inner_card">
                  <div className="text-xl font-bold mb-4 income_head">
                    <span>Income</span>
                  </div>
                  <div className="flex justify-between income_body">
                    {income &&
                      Object.keys(income)?.map((value) => {
                        return (
                          <div className="text-center income_body_inner">
                            <img src={income[value].icon} alt="" />
                            <div>
                              <span className="curr_sign">
                                {income[value].sign}
                              </span>
                              <span className="income_amount">
                                {income[value].income_amount}
                              </span>
                            </div>
                            <p className="income_description">
                              {income[value].income_decription}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              {/* Overall Score Card */}
              {/* <div className="bg-white p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-bold mb-4">Overall Score</h2>
                                <div className="text-center text-2xl font-bold mb-4">4.8/5</div>
                                <div className="flex justify-between">
                                    <div className="text-center">
                                        <p>Punctuality</p>
                                        <p className="text-2xl font-bold">4.0</p>
                                    </div>
                                    <div className="text-center">
                                        <p>Hygiene</p>
                                        <p className="text-2xl font-bold">4.0</p>
                                    </div>
                                    <div className="text-center">
                                        <p>Professionalism</p>
                                        <p className="text-2xl font-bold">4.0</p>
                                    </div>
                                    <div className="text-center">
                                        <p>Work Quality</p>
                                        <p className="text-2xl font-bold">4.0</p>
                                    </div>
                                </div>
                            </div> */}
              <div className="bg-white p-6 rounded-lg shadow-md overall">
                <div className="monthly_block">
                  <h2 className="monthly_text">Monthly Score</h2>
                  <div className="monthly_rating">
                    <div className="rating_text">
                      <span className="total_rating">4.8</span>
                      <span className="out_of">/5</span>
                    </div>
                    <div className="rating_star">
                      <img src={RatingIcon} alt="" />
                    </div>
                  </div>
                  <div className="monthly_reviews">
                    <span className="reviews">13 Reviews</span>
                  </div>
                </div>

                <div className="vertical_line"></div>

                <div className="yearly_block">
                  <h2 className="yearly_text">Yearly Score</h2>
                  <div className="yearly_rating">
                    <div className="rating_text">
                      <span className="total_rating">4.8</span>
                      <span className="out_of">/5</span>
                    </div>
                    <div className="rating_star">
                      <img src={RatingIcon} alt="" />
                    </div>
                  </div>
                  <div className="yearly_reviews">
                    <span className="reviews">13 Reviews</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
              {/* Week at a Glance Card */}
              <div className="bg-white p-6 rounded-lg shadow-md week_glance">
                <div className="week_body">
                  <div className="week_glance_head">
                    <span className="week_heading">Week at a Glance</span>
                    <span className="job_summary">Job Summary</span>
                  </div>
                  <div className="flex justify-between week_glance_content">
                    <div className="text-center total_jobs">
                      <img src={InfoIcon} alt="" />
                      <p className="count">9</p>
                      <p className="head">Total Jobs</p>
                      <span className="description">+5% from last week</span>
                    </div>
                    <div className="text-center jobs_completed">
                      <img src={TagIcon} alt="" />
                      <p className="count">5</p>
                      <p className="head">Jobs Completed</p>
                      <span className="description">+1,2% from last week</span>
                    </div>
                    <div className="text-center jobs_cancelled">
                      <img src={UserAddIcon} alt="" />
                      <p className="count">1</p>
                      <p className="head">Jobs Cancelled</p>
                      <span className="description">+0% from last week</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Month at a Glance Card */}
              <div className="bg-white p-6 rounded-lg shadow-md month_glance">
                <div className="month_body">
                  <div className="month_glance_head">
                    <span className="month_heading">Month at a Glance</span>
                    <span className="job_summary">Job Summary</span>
                  </div>
                  <div className="flex justify-between month_glance_content">
                    <div className="text-center total_jobs">
                      <img src={InfoIcon} alt="" />
                      <p className="count">39</p>
                      <p className="head">Total Jobs</p>
                      <span className="description">+5% from last month</span>
                    </div>
                    <div className="text-center jobs_completed">
                      <img src={TagIcon} alt="" />
                      <p className="count">23</p>
                      <p className="head">Jobs Completed</p>
                      <span className="description">+1,2% from last month</span>
                    </div>
                    <div className="text-center jobs_cancelled">
                      <img src={UserAddIcon} alt="" />
                      <p className="count">2</p>
                      <p className="head">Jobs Cancelled</p>
                      <span className="description">+0% from last month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <Footer currentYear={currentYear} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
