/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Success = ({ successMessage }) => {

  React.useEffect(() => {
    if (successMessage) {
      toast.success(`Success! ${successMessage}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        success: {
          style: {
            color: "#395E30",
          },
        },
      });
    }
  }, [successMessage]);

  return (
    <div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default Success;
