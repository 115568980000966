import React from 'react';
import ModalLogo from "../../../../assets/modal_logo.svg"
import "../../../../styles/postlogin/services.css"
import { useNavigate } from "react-router-dom";
import * as routePath from "../../../../routes"

const BookingConfirmModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate()
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 booking_confirm" style={{ marginTop: "0px" }}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 md:mx-0">
                <div className="text-center body">
                    <img
                        src={ModalLogo}
                        alt="DoSpeedo Logo"
                        className="mx-auto m-4"
                        width={"326px"}
                        height={"326px"}
                    />
                    <h2 className="mb-2 w-full booking_confirm_heading">
                        Booking Completed Successfully!
                    </h2>
                    <p className="first_text">
                        💫  Get ready for Premium cleaning experience
                    </p>
                    <p className="second_text mt-2">
                        ☎️  Now you will receive a call to confirm your booking.
                    </p>
                    <div className="w-full mt-4 flex gap-4 justify-center space-x-4 modal_button_section">
                        <button
                            className="w-full px-4 py-2 modal_view_button"
                            onClick={() => navigate(`/${`${routePath.CustomerLayoutPath}`}/${routePath.bookingListPath}`)}
                        >
                            View
                        </button>
                        <button
                            className=" px-4 py-2 modal_home_button"
                            onClick={() => navigate(`/${routePath.homePath}`)}
                        >
                            Home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingConfirmModal;
