import React, { useEffect, useState } from 'react'
import { getRequest } from "../../../../interceptor/AxiosConfig"
import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Error from "../../../../components/Alert/Error";

import Header from "../../../../components/Header/index"
import Footer from "../../../../components/Footer/Footer"
import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"

const RefundPolicy = () => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [RefundPolicy, setRefundPolicy] = useState(null)

    const getRefundPolicy = async () => {
        await getRequest(`${apiEndpoint.tcPPAPIEndpoint}`).then((res) => {
            if (res.status === 200) {
                setRefundPolicy(res.data.data["refund-policy"])
            }
        }).catch((err) => {
            // setErrorMessage(ErrorMessageHandling(err))
        });
    }

    useEffect(() => {
        getRefundPolicy()
    }, [])


    return (
        <div>
            {errorMessage && <Error errorMessage={errorMessage} />}

            <Header />
            <div className="container mx-auto min-h-screen bg-gray-100 py-8">
                <div className="w-full">
                    <div className="col-span-2 bg-white p-6 rounded-lg shadow">
                        <div className="flex justify-center pb-6">
                            <h1 className="uppercase text_first">{RefundPolicy?.title}</h1>
                        </div>
                        <div className="text-gray-800 leading-relaxed">
                            <p className="mb-4">
                                <div dangerouslySetInnerHTML={{ __html: RefundPolicy?.description }} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RefundPolicy