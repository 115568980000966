import { createSlice } from "@reduxjs/toolkit";

const PRICE_INITIAL_STATE = {
    offerPrice: null,
    originalPrice: null,
    totalPrice: null,
    couponDetail: null,
    gstPrice: null,
    membershipPrice: 0,
    referralPrice: 0,
    couponPrice: 0,
    membershipPercent: 0,
    referralPercent: 0,
    couponPercent: 0,
    offerPercent: 0,
}

const PriceSlice = createSlice({
    name: "price",
    initialState: PRICE_INITIAL_STATE,
    reducers: {
        setOfferPrice: (state, action) => {
            state.offerPrice = action.payload
        },
        setOriginalPrice: (state, action) => {
            state.originalPrice = action.payload
        },
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload
        },
        setCouponDetails: (state, action) => {
            state.couponDetail = action.payload
        },
        setGSTPrice: (state, action) => {
            state.gstPrice = action.payload
        },
        setMembershipPrice: (state, action) => {
            state.membershipPrice = action.payload
        },
        setReferralPrice: (state, action) => {
            state.referralPrice = action.payload
        },
        setCouponPrice: (state, action) => {
            state.couponPrice = action.payload
        },
        setMembershipPercent: (state, action) => {
            state.membershipPercent = action.payload
        },
        setReferralPercent: (state, action) => {
            state.referralPercent = action.payload
        },
        setCouponPercent: (state, action) => {
            state.couponPercent = action.payload
        },
        setOfferPercent: (state, action) => {
            state.offerPercent = action.payload
        }
    }
})

export const { setOfferPrice, setOriginalPrice, setTotalPrice, setCouponDetails,
    setGSTPrice, setMembershipPrice, setReferralPrice, setCouponPrice,
    setMembershipPercent, setReferralPercent, setCouponPercent, setOfferPercent
} = PriceSlice.actions;
export default PriceSlice.reducer;