import React, { useState } from "react";
import ProgressBar from "../progressbar/ProgressBar";
import CloseIcon from "../../assets/icons/closewhitebg.svg"
import { capitalizeFirstLetter, convertTo12Hour, formateDateTwo } from "../../utilsfunction/Functions";
import * as routePath from "../../routes"
import { useNavigate } from "react-router-dom";
import FormError from "../Alert/FormError";

const BookingDetailsModal = ({ bookingDetails, isReviewModalOpen, setReviewModalOpen, handleBookAgain }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(false)
    // handle navigation
    const handleNavigate = (id) => {
        if (id) {
            navigate(`/${routePath.CustomerLayoutPath}/${routePath.reviewPath}/${id}`)
        } else {
            setError(`Job Details not found`)
        }
    }

    return (
        <div>
            {/* Review Modal */}
            {isReviewModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-90 min-h-full flex justify-center items-center modal_dialog">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-lg md:w-96 max-h-screen modal_content overflow-hidden">
                        {/* Modal Header */}
                        <div className="modal_header">
                            <div className="flex justify-between items-center p-4">
                                <div className="head mb-2 flex gap-4 items-center">
                                    Booking No <span>{bookingDetails?.booking_number}</span>
                                </div>
                                <button onClick={() => setReviewModalOpen(!isReviewModalOpen)}>
                                    <img src={CloseIcon} alt="close" width="24px" height="24px" />
                                </button>
                            </div>
                        </div>

                        {/* Progress Bar */}
                        <div className="flex flex-col justify-between items-center mb-2 bg-gray-100">
                            <div className="w-full mt-4 p-4">
                                <ProgressBar step1={true} step2={true} step3={true} />
                            </div>
                        </div>

                        {/* Modal Body with scroll */}
                        <div className="p-4 overflow-y-auto flex-1 modal_body">
                            <div className="mb-4 text-green font-lg flex gap-4  items-center bookingdetails_header">
                                Booking Details
                            </div>
                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Selected Service</p>
                                <div className="flex gap-2 items-center">
                                    <p className="secondHead">{bookingDetails?.Service?.name?.split(" - ")[0]}</p>
                                    <span className="round_dot"></span>
                                    <p className="secondHead">{bookingDetails?.Service?.name?.split(" - ")[1]}</p>
                                </div>
                            </div>

                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Service Details</p>
                                <p className="secondHead" dangerouslySetInnerHTML={{ __html: bookingDetails?.Service?.description }}></p>
                            </div>

                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Working time</p>
                                <p className="secondHead">{formateDateTwo(bookingDetails?.date)} | {convertTo12Hour(bookingDetails?.start_time)}</p>
                            </div>

                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Location</p>
                                <p className="secondHead">{bookingDetails?.location}</p>
                            </div>

                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Note</p>
                                <p className="secondHead">{bookingDetails?.note}</p>
                            </div>

                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Domestic worker</p>
                                <p className="secondHead">{bookingDetails?.job?.employee?.full_name}</p>
                            </div>

                            <div className="text-left mb-4 block pb-3">
                                <p className="firstHead">Working hour</p>
                                <p className="secondHead">{bookingDetails?.job?.employee?.working_hour}</p>
                            </div>

                            <div className="text-left mb-4">
                                <p className="firstHead">Cost & Payment Method</p>
                                <span className="secondHead">Rs: {bookingDetails?.price}</span> | <span className="secondHead">{capitalizeFirstLetter(bookingDetails?.payment_method)}</span>
                            </div>
                        </div>

                        {error && <FormError error={error} />}

                        {/* Modal Footer */}
                        <div className="flex  gap-2 justify-between p-4">
                            <button className="btn_secondary" onClick={() => { handleNavigate(bookingDetails?.job?.id) }}>
                                Review
                            </button>
                            <button className="modal_btn btn_primary" onClick={() => handleBookAgain(bookingDetails?.Service?.id)}>Book Again</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingDetailsModal;
