/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axios from 'axios';

const useCountryState = (lat, lon) => {
    const [userLocation, setUserLocation] = useState({
        lat: null,
        lon: null,
        country: '',
        state: '',
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchCountryState = async () => {
        const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(url);
            const addressComponents = response.data.results[0].address_components;
            const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || '';
            const state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || '';
            const fullAddress = response.data.results[0].formatted_address || '';

            const locationData = { lat, lon, country, state, fullAddress };

            setUserLocation(locationData);
        } catch (error) {
            setError('Failed to fetch country and state.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (lat && lon) {
            fetchCountryState();  // trigger the api call only if lat and lon are provided
        }
    }, [lat, lon]);

    return { userLocation, loading, error };
};

export default useCountryState;
