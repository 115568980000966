/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../../../styles/common.css";
import "../../../../styles/footer.css";

const Footer = ({ currentYear }) => {
  return (
    <div className="footer">
      {/* Footer */}
      <footer className="mt-8 text-center bg-white shadow-lg p-4 rounded-lg footer_section">
        <div className="text-gray-500 footer_text">
          <span className="footer_first">Copyright {currentYear},&nbsp;</span>
          <span className="footer_second">DoSpeedo Pvt Ltd</span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
