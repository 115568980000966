import React from 'react';

const CouponDetails = ({ title, code, discount }) => {
    return (
        <div className="p-6 pt-2 bg-white border border-gray-200 shadow-sm hover:shadow-lg transition duration-300 ease-in-out mb-4">
            <h2 className="heading_text2">{title}</h2>
            <p className="mt-2 text-left content_text2">
                Discount: <span className="text-left content_text3">{discount}</span>
            </p>
            {/* <div className="mt-4 flex justify-between items-center">
                <div>
                    <p className="text-gray-500">Coupon Code:</p>
                    <div className="flex items-center gap-2 mt-1">
                        <span className="px-2 py-1 bg-gray-100 text-gray-800 font-semibold rounded">{code}</span>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default CouponDetails;
