/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import * as routePath from "../../routes";
import RegisterSuccess from "../../assets/prelogin/register_success.svg";

import CompanyLogo from "../../assets/dospeedo.svg";
import VectorGrp from "../../assets/prelogin/register_graphic.png";

const RegistrationSuccess = () => {
  const navigate = useNavigate();

  return (
    <section className="container mx-auto">
      <div className="relative grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 overflow-hidden py-24 registerOuter">
        <div className="registerInner">
          <div className="registerContent text-white">
            <div class="flex justify-center items-center mb-12">
              <img src={CompanyLogo} alt="Company Logo" />
            </div>
            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Mission
              </h2>
              <p>
                <strong>
                  “Simplifying cleaning and empowering lives with a click”
                </strong>
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-5">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Vision
              </h2>
              <p>
                Building a community where cleaning professionals are valued and
                empowered to make a difference by providing solution to cleaning
                challenges
              </p>
            </div>

            <div className="missionBox registerContentBox text-center my-10">
              <h2 className="text-green-600 text-lg font-semibold">
                Our Services
              </h2>
              <ul>
                <li>Brooming and Mopping</li>
                <li>Residential Cleaning</li>
                <li>Utensils Cleaning</li>
                <li>Commercial Cleaning</li>
                <li>Kitchen Cleaning </li>
                <li>Full Home Cleaning</li>
                <li>Bathroom Cleaning</li>
                <li>Special Occasion Service</li>
              </ul>
            </div>
          </div>
          <div className="registerGrapic">
            <img src={VectorGrp} alt="Banner Graphic Img" />
          </div>
        </div>

        <div className="registerFormWrap">
          <div className="flex flex-col gap-0">
            <div className="mx-auto w-full login_heading">
              <div className="prelogin_heading_first flex justify-content-center align-items-center">
                <span></span>
                <span className="">Successfull</span>
              </div>
              <div className="formDescription">
                <p>You have successfully completed the onboarding process.</p>
              </div>
            </div>
            <div className="registration_form_box successBox w-full mt-4 mb-4 mx-auto bg-white border border-gray-200 shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
              <div className="p-4 pt-24">
                <div className="mt-0">
                  <form>
                    <div className="formGrid grid gap-y-4">
                      <div className="relative w-full mb-14">
                        <img
                          className="mx-auto"
                          src={RegisterSuccess}
                          alt="success"
                        />
                      </div>
                      <div className="flex items-center">
                        <button
                          type="submit"
                          className="bg_button_color w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white disabled:opacity-50 disabled:pointer-events-none"
                          onClick={() => {
                            navigate(`/${routePath.homePath}`);
                          }}
                        >
                          Go To Home Screen
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegistrationSuccess;
