/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../styles/home/header.css";
import CompanyLogo from "../../assets/dospeedo.svg";
import ProfileIcon from "../../assets/home/profileIcon.svg";
import NotificationIcon from "../../assets/home/notification.svg";
import { Link } from 'react-router-dom';

// import NotificationIconDot from "../../assets/home/notificationwithdot.svg";

import { useLocation, useNavigate } from "react-router-dom";
import * as route from "../../routes";
import { LocalStorage } from "../../utilsfunction/Encrypt";
import { useDispatch } from "react-redux";
import { toggleOffcanvas } from "../Reducers/NotificationReducer";

import CloseIcon from "../../assets/icons/closewhitebg.svg"

const Index = () => {
  const localstorage = new LocalStorage();
  const location = useLocation();
  const navigate = useNavigate();

  // for notifications
  const dispatch = useDispatch();

  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    if (location.pathname.includes("/home")) {
      setActiveLink("home");
    }
  }, [location.pathname]);

  // checking for login status
  let auth = localstorage.getItem("1");

  const [headerOffcanvas, setHeaderOffcanvas] = useState(false)

  useEffect(() => {
    setHeaderOffcanvas(false)
  }, [location.pathname])

  const navgateServices = () => {
    if (!auth) {
      navigate(`/home/${route.servicesListPath}`);
    } else if (auth) {
      navigate(`/${route.CustomerLayoutPath}/${route.servicesListPath}`);
    }
  }

  return (
    <header className="container-fluid mx-auto bg-white shadow header_header">
      <div className="opacity-95 headerHead">
        <div className="flex justify-between items-center py-4">
          <div className="flex-shrink-0 header_company_logo" style={{ cursor: 'pointer' }}>
            <img src={CompanyLogo} alt="Company Logo" onClick={() => {
              navigate(`/${route.homePath}`);
              handleLinkClick("home");
            }} />
          </div>
          <div className="flex items-center gap-4">
            <nav className="hidden lg:flex header_nav">
              <a
                className={`flex header_nav_link cursor-pointer ${activeLink === "home" ? "active" : ""
                  }`}
              >
                <span
                  className="header_link"
                  onClick={() => {
                    navigate(`/${route.homePath}`);
                    handleLinkClick("home");
                  }}
                >
                  Home
                </span>
              </a>
              {/* {auth && ( */}
              <a
                className={`flex header_nav_link cursor-pointer ${activeLink === "services" ? "active" : ""
                  }`}
                onClick={() => { navgateServices(); handleLinkClick("services") }}
              >
                <span className="services_link">Services</span>
              </a>
              {/* )} */}
              <a
                className={`flex header_nav_link cursor-pointer ${activeLink === "about" ? "active" : ""
                  }`}
                onClick={() => {
                  handleLinkClick("about");
                  navigate(`/${route.aboutUsPath}`);
                }}
              >
                <span className="about_link">About</span>
              </a>
              <a
                className={`flex header_nav_link cursor-pointer ${activeLink === "contactus" ? "active" : ""
                  }`}
                onClick={() => {
                  handleLinkClick("contactus");
                  navigate(`/${route.contactUsPath}`);
                }}
              >
                <span className="contact_link">Contact Us</span>
              </a>
              {auth && (
                <>
                  {" "}
                  <a
                    className={`flex header_nav_link cursor-pointer ${activeLink === "booking" ? "active" : ""
                      }`}
                    onClick={() => { navigate(`/${route.CustomerLayoutPath}/${route.bookingListPath}`); handleLinkClick("booking") }}
                  >
                    <span className="header_link bookings_link">
                      My Bookings
                    </span>
                  </a>
                  <a
                    className={`flex header_nav_link cursor-pointer ${activeLink === "subscription" ? "active" : ""
                      }`}
                    onClick={() => { navigate(`/${route.CustomerLayoutPath}/${route.subscriptionPath}`); handleLinkClick("subscription") }}
                  >
                    <span className="header_link subscription_link">
                      Membership
                    </span>
                  </a>
                  <div className="flex gap-2 -mt-2">
                    <a className="flex header_nav_link cursor-pointer" onClick={() => dispatch(toggleOffcanvas())} >
                      <span className="header_link">
                        <img src={NotificationIcon} alt={"Notification"} />
                      </span>
                    </a>
                    <a className="flex header_nav_link cursor-pointer" onClick={() => navigate(`/${route.CustomerLayoutPath}/${route.profilePath}`)}>
                      <span className="header_link">
                        <img src={ProfileIcon} alt={"Profile"} />
                      </span>
                    </a>
                  </div>
                </>
              )}
            </nav>
            {!auth && (
              <div className="hidden lg:flex header_log_btn cursor-pointer">
                <Link to={`/${route.preloginPath}/${route.LoginPath}`} className="btn_text">
                  Login/Sign Up
                </Link>
              </div>
            )}
          </div>
          <div className="lg:hidden">
            <button className="text-gray-600 hover:text-green-600 focus:outline-none focus:text-green-600" onClick={() => setHeaderOffcanvas(!headerOffcanvas)}>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

        </div>
      </div>

      <div>
        <div
          style={{ marginTop: "0px" }}
          className={`lg:hidden fixed right-0 justify-end inset-0 z-40 flex h-full ${headerOffcanvas ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300`}
        >
          <div
            className="fixed inset-0 bg-black opacity-90"
            onClick={() => setHeaderOffcanvas(!headerOffcanvas)}
          >
          </div>

          {/* Off-Canvas Content */}
          <div className="offcanvas_body relative bg-white w-10/12 h-full p-0 right-0 fixed overflow-y-auto">
            <div className="offcanvas_head flex justify-between items-center p-4">
              <div></div>
              <img src={CloseIcon} alt="Close" className="cursor-pointer" onClick={() => setHeaderOffcanvas(!headerOffcanvas)} />

            </div>
            <div className="body flex flex-col justify-between border-t border-gray-200 p-4">
              <nav className="flex flex-col header_nav">
                <a
                  className={`flex header_nav_link cursor-pointer ${activeLink === "home" ? "active" : "text_black"
                    }`}
                >
                  <span
                    className="header_link"
                    onClick={() => {
                      navigate(`/${route.homePath}`);
                      handleLinkClick("home");
                    }}
                  >
                    Home
                  </span>
                </a>
                {/* {auth && ( */}
                <a
                  className={`flex header_nav_link cursor-pointer ${activeLink === "services" ? "active" : "text_black"
                    }`}
                  onClick={() => { navgateServices(); handleLinkClick("services") }}
                >
                  <span className="services_link">Services</span>
                </a>
                {/* )} */}
                <a
                  className={`flex header_nav_link cursor-pointer ${activeLink === "about" ? "active" : "text_black"
                    }`}
                  onClick={() => {
                    handleLinkClick("about");
                    navigate(`/${route.aboutUsPath}`);
                  }}
                >
                  <span className="about_link">About</span>
                </a>
                <a
                  className={`flex header_nav_link cursor-pointer ${activeLink === "contactus" ? "active" : "text_black"
                    }`}
                  onClick={() => {
                    handleLinkClick("contactus");
                    navigate(`/${route.contactUsPath}`);
                  }}
                >
                  <span className="contact_link">Contact Us</span>
                </a>
                {auth && (
                  <>
                    {" "}
                    <a
                      className={`flex header_nav_link cursor-pointer ${activeLink === "booking" ? "active" : "text_black"
                        }`}
                      onClick={() => { navigate(`/${route.CustomerLayoutPath}/${route.bookingListPath}`); handleLinkClick("booking") }}
                    >
                      <span className="header_link bookings_link">
                        My Bookings
                      </span>
                    </a>
                    <a
                      className={`flex header_nav_link cursor-pointer ${activeLink === "subscription" ? "active" : "text_black"
                        }`}
                      onClick={() => { navigate(`/${route.CustomerLayoutPath}/${route.subscriptionPath}`); handleLinkClick("subscription") }}
                    >
                      <span className="header_link subscription_link">
                        Subscription
                      </span>
                    </a>
                    <div className="flex flex-col gap-4">
                      <a className="flex header_nav_link cursor-pointer" onClick={() => dispatch(toggleOffcanvas())} >
                        <span className="header_link">
                          <img src={NotificationIcon} alt={"Notification"} />
                        </span>
                      </a>
                      <a className="flex header_nav_link cursor-pointer" onClick={() => navigate(`/${route.CustomerLayoutPath}/${route.profilePath}`)}>
                        <span className="header_link">
                          <img src={ProfileIcon} alt={"Profile"} />
                        </span>
                      </a>
                    </div>
                  </>
                )}
              </nav>
              {!auth && (
                <div className="lg:flex header_log_btn cursor-pointer mt-2">
                  <Link to={`/${route.preloginPath}/${route.LoginPath}`} className="btn_text">
                    Login/Sign Up
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Index;
