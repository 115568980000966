import { getRequest } from "../interceptor/AxiosConfig"
import * as apiEndpoint from "../apis/postlogin/PostloginApis"
import ErrorMessageHandling from "./ErrorHandling"

// getting all address list
export const getAddress = async (state, error) => {
    await getRequest(`${apiEndpoint.bookingAddressAPIEndpoint}`)
        .then((response) => {
            if (response.status === 200) {
                state(response.data.data)
            }
        }).catch((err) => {
            error(ErrorMessageHandling(err))
        })
}