/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/index";
import Footer from "../../../components/Footer/Footer";
import { setMessage } from "../../../components/Reducers/MessageReducer";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const BlogDetails = () => {
    const { id } = useParams(); // Extract blog ID from the URL
    const location = useLocation();
    const dispatch = useDispatch();
    const [blog, setBlog] = useState(null); // State to hold blog data
    const [error, setError] = useState(null); // State to handle errors

    useEffect(() => {
        // Clear message if not on login page
        if (!location.pathname.includes("/dospeedo/login")) {
            dispatch(setMessage(null));
        }
    }, [location.pathname.includes("/dospeedo/login")]);

    useEffect(() => {
        // Fetch blog details by ID
        const fetchBlogDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}common/blogs/${id}`);
                const data = await response.json();
                if (response.ok) {
                    setBlog(data);
                } else {
                    setError(data.message || "Failed to load blog details.");
                }
            } catch (err) {
                setError("An error occurred while fetching blog details.");
                console.error("Error fetching blog:", err);
            }
        };

        if (id) {
            fetchBlogDetails();
        }
    }, [id]);

    return (
        <>
            <Header />
            <div className="container mx-auto min-h-screen bg-gray-100 py-8">
                <div className="w-full">
                    {/* Main Content */}
                    <div className="col-span-2 bg-white p-6">
                        {error && <p className="text-red-500 text-center">{error}</p>}
                        {!error && !blog && (
                            <p className="text-center text-gray-600">Loading...</p>
                        )}
                        {blog && (
                            <>
                                {/* Blog Image */}
                                {/* <div className="flex justify-center pb-6">
                                    <h1 className="uppercase blogheading">{blog.title}</h1>
                                </div> */}
                                <div className="relative">
                                    <img
                                        src={blog.banner_image}
                                        alt={blog.title}
                                        className="w-full h-auto"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-70"></div>
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <span className="text-white heading_text">
                                            {blog.title}
                                        </span>
                                    </div>
                                </div>

                                {/* Blog Content */}
                                <div className="mt-4">
                                    <div
                                        className="mb-4 content_text"
                                        dangerouslySetInnerHTML={{ __html: blog.content }}
                                    ></div>
                                </div>

                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlogDetails;
