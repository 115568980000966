import React from "react";
import "../../../../styles/header.css";
import MagnifierIcon from "../../../../assets/icons/magnifier.svg";
import ProfilePic from "../../../../assets/icons/avatar.svg";

const Header = () => {
  // function for showing and closing the sidebar
  const sidebar = document.querySelector(".sidebar_main");
  function toggleSidebar() {
    sidebar.classList.toggle("active");
  }

  return (
    <div>
      <header className="top_bar flex lg:flex-row justify-between items-center w-full m-0">
        <div className="search-container">
          <img src={MagnifierIcon} alt="MyJobs" className="search-icon" />
          <input
            type="search"
            className="search-input"
            placeholder="Search here..."
          />
        </div>
        <div className="profile">
          <span className="profile-text">Namaste</span>
          <span className="profile-name font-bold text-green-500">Jane!</span>
          <img
            src={ProfilePic}
            alt="Profile pic"
            onClick={toggleSidebar}
            className="cursor-pointer"
          />
        </div>
      </header>
    </div>
  );
};

export default Header;
