import { useState } from 'react';
import axios from 'axios';
import { LocalStorage } from "./Encrypt";

const UserLocation = () => {
    const localstorage = new LocalStorage()
    const [userLocation, setUserLocation] = useState({ lat: '', lon: '', country: '', state: '' });
    const [locationError, setErrorLocation] = useState('');
    const [permissionStatus, setPermissionStatus] = useState('');

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lon = position.coords.longitude;
                    setUserLocation((prevState) => ({ ...prevState, lat, lon }));
                    getCountryState(lat, lon);
                    setPermissionStatus('allowed');
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setPermissionStatus('denied');
                            setErrorLocation('User denied the request for Geolocation.');
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setPermissionStatus('unavailable');
                            setErrorLocation('Location information is unavailable.');
                            break;
                        case error.TIMEOUT:
                            setPermissionStatus('timeout');
                            setErrorLocation('The request to get user location timed out.');
                            break;
                        case error.UNKNOWN_ERROR:
                            setPermissionStatus('unknown');
                            setErrorLocation('An unknown error occurred.');
                            break;
                        default:
                            setPermissionStatus('unknown');
                            setErrorLocation('An unknown error occurred.');
                            break;
                    }
                }
            );
        } else {
            setPermissionStatus('unsupported');
            setErrorLocation('Geolocation is not supported by this browser.');
        }
    };

    const getCountryState = async (lat, lon) => {
        const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`

        try {
            const response = await axios.get(url);
            const addressComponents = response.data.results[0].address_components;
            const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || '';
            const state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || '';

            setUserLocation((prevState) => ({ ...prevState, country, state }));
            localstorage.setItem("userLocation", { lat: lat, lon: lon, country: country, state: state })
        } catch (error) {
            setUserLocation('Failed to fetch country and state.');
        }
    };
    return { userLocation, permissionStatus, locationError, getUserLocation };
};

export default UserLocation;
