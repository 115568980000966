/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// import FormError from "../Alert/FormError";
import { Map, Marker } from "@vis.gl/react-google-maps";
import "../../styles/postlogin/services.css"
import FormError from "../Alert/FormError";
import { useDispatch, useSelector } from "react-redux";
import { setMarkerPosition } from "../Reducers/GeoLocationReducer";
import useCountryState from "../../helper/geoLocationHook";
import { getRequest, postRequest } from "../../interceptor/AxiosConfig";
import * as apiEndpoint from "../../apis/postlogin/PostloginApis"
import { getAddress } from "../../utilsfunction/GetApis";

// const loadGoogleMapsScript = (callback) => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
//     script.async = true;
//     script.onload = callback;
//     document.head.appendChild(script);
// };


// Modal Component for adding a new address
const AddAddressModal = ({ show, setShow, onClickFn, onChangeFn, formErrors, addressValues, setAddressValues, setAddressList,
    setSuccessMessage,
    setErrorMessage,
    setShowModal

}) => {

    const dispatch = useDispatch();

    const dropdownRefAddressType = useRef(null);

    const inputRef = useRef(null); // Define inputRef here
    // const [successMessage, setSuccessMessage] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);
    // const [showModal, setShowModal] = useState(false);

    const [isAddressTypeVisible, setAddressTypeVisible] = useState(false);
    const [addressTypeOption, setAddressTypeOption] = useState(null);

    const handleAddressTypeClick = (option) => {
        setAddressValues({ ...addressValues, "type": option });
        setAddressTypeOption(option);
        setAddressTypeVisible(false);
    };

    // function to handle clicking outside of the input and dropdown
    const handleClickOutside = (event) => {
        if (dropdownRefAddressType.current && !dropdownRefAddressType.current.contains(event.target)) {
            setAddressTypeVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleAddressTypeSelect = () => {
        setAddressTypeVisible(true)
    }


    const { markerPosition } = useSelector((state) => state?.geoLocation);
    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();

        // Update the marker's position in the state
        dispatch(setMarkerPosition({ lat: newLat, lng: newLng }));
    };

    const { userLocation } = useCountryState(markerPosition?.lat, markerPosition?.lng);

    const [closeLocationTitle, setCloseLocationTitle] = useState(true)

    useEffect(() => {
        if (userLocation) {
            setAddressValues({ ...addressValues, location_name: userLocation?.fullAddress, state: userLocation?.state });
        }
        if (!closeLocationTitle) {
            setCloseLocationTitle(!closeLocationTitle)
        }
    }, [userLocation]);


    const getLatLng = async (value) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
            const response = await getRequest(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=${apiKey}`
            );

            if (response.data.status === 'OK') {
                const location = response.data.results[0].geometry.location;

                // Set the marker position and refresh the map
                dispatch(setMarkerPosition({ lat: location.lat, lng: location.lng }));

                // Extract the address components and populate the input fields
                const addressComponents = response.data.results[0].address_components;
                const formattedAddress = response.data.results[0].formatted_address;

                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name;

                // Set address values to fill input fields
                setAddressValues({
                    ...addressValues,
                    location_name: formattedAddress,
                    state: state || addressValues.state,
                    block: addressComponents.find(component => component.types.includes('route'))?.long_name || '',
                    house_number: addressComponents.find(component => component.types.includes('street_number'))?.long_name || ''
                });
            } else {
                // Optionally set an error message
            }
        } catch (err) {
            // Optionally set an error message
        }
    };

    // In the autocomplete setup
    const loadGoogleMapsScript = (callback) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.onload = callback;
        document.head.appendChild(script);
    };

    useEffect(() => {
        // loadGoogleMapsScript(() => {
        //     const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

        //     autocomplete.addListener('place_changed', () => {
        //         const place = autocomplete.getPlace();
        //         if (place.geometry) {
        //             const location = place.geometry.location;
        //             // Update marker position
        //             dispatch(setMarkerPosition({ lat: location.lat(), lng: location.lng() }));

        //             // Update address values with the formatted address
        //             const formattedAddress = place.formatted_address;
        //             const addressComponents = place.address_components;

        //             const state = addressComponents.find(component =>
        //                 component.types.includes('administrative_area_level_1')
        //             )?.long_name;

        //             setAddressValues({
        //                 ...addressValues,
        //                 location_name: formattedAddress,
        //                 state: state || addressValues.state,
        //             });
        //         }
        //     });
        // });
    }, []);

    const handleMapSearch = async () => {
        loadGoogleMapsScript(() => {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const location = place.geometry.location;
                    // Update marker position
                    dispatch(setMarkerPosition({ lat: location.lat(), lng: location.lng() }));

                    // Update address values with the formatted address
                    const formattedAddress = place.formatted_address;
                    const addressComponents = place.address_components;

                    const state = addressComponents.find(component =>
                        component.types.includes('administrative_area_level_1')
                    )?.long_name;

                    setAddressValues({
                        ...addressValues,
                        location_name: formattedAddress,
                        state: state || addressValues.state,
                    });
                }
            });
        });
    }


    // Add this function inside your AddAddressModal component
    const saveAddress = async () => {

        formErrors.block = '';
        formErrors.house_number = '';
        formErrors.location_name = '';
        formErrors.type = '';

        // Validation
        let hasError = false;

        if (!addressValues.block) {
            formErrors.block = "Apartment/Road/Area is required.";
            hasError = true;
        }
        if (!addressValues.house_number) {
            formErrors.house_number = "House/Flat No is required.";
            hasError = true;
        }
        if (!addressValues.location_name) {
            formErrors.location_name = "Location's Name is required.";
            hasError = true;
        }
        if (!addressTypeOption) {
            formErrors.type = "Address Type is required.";
            hasError = true;
        }

        // If there are validation errors, set them and return
        if (hasError) {
            return; // Prevent further execution
        }

        try {
            // Prepare the data to be saved
            const addressData = {
                block: addressValues.block,
                house_number: addressValues.house_number,
                location_name: addressValues.location_name,
                type: addressTypeOption,
                lat: markerPosition.lat,
                lng: markerPosition.lng
            };

            // return console.log('addressData',addressData);


            await postRequest(`${apiEndpoint.AddAddressAPIEndpoint}`, addressValues)
                .then((response) => {
                    if (response.status === 201) {
                        // window.location.reload();
                        setSuccessMessage(response.data.message)
                        setErrorMessage(false)
                        setShowModal(false)
                        getAddress(setAddressList, setErrorMessage)
                        setAddressValues(null)
                    }
                }).catch((err) => {
                    // setErrorMessage(ErrorMessageHandling(err))
                    // setErrorMessage("")
                    setSuccessMessage(false)
                    setShowModal(false)
                })

        } catch (error) {
            console.error("Error saving address:", error);
            // setErrorMessage("An error occurred while saving the address.");
        }
    };

    return (
        <div className={`fixed overflow-scroll inset-0 md:px-20 flex items-center justify-center bg-black bg-opacity-90 ${show ? 'block' : 'hidden'}`}>
            <div className="bg-white p-4 rounded-lg shadow-lg w-full">

                <section className="w-full bg-white rounded-lg p-6 pb-4 pick_location">
                    <div className="flex justify-between">
                        <div>
                            <span className="first_head">Add New </span><span className="second_head">Address</span>
                        </div>
                    </div>

                    {/* location search */}
                    <input
                        type="text"
                        ref={inputRef}
                        placeholder='Search a location'
                        className='input_field mt-1 block w-full'
                        name="search_address"
                        onChange={() => handleMapSearch()}
                    />

                    <div className="mt-4 pick_location_body">
                        <div className="w-full rounded-lg shadow-sm h-72">

                            <Map
                                style={{ borderRadius: "20px" }}
                                defaultZoom={13}
                                center={markerPosition}
                                gestureHandling={"greedy"}
                                disableDefaultUI
                            >
                                <Marker
                                    position={markerPosition}
                                    draggable={true}
                                    onDragEnd={handleMarkerDragEnd}
                                />
                            </Map>



                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 pick_location_fields">
                            <div className="relative">
                                <input
                                    type="text"
                                    value={addressValues?.block} // Changed from defaultValue to value
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.block ? `form_error_border` : `border-gray-200`}`}
                                    name="block"
                                    onChange={(e) => {
                                        setAddressValues({ ...addressValues, block: e.target.value }); // Update state
                                    }}
                                />
                                <span className="input_placeholder input_placeholder_area">Apartment/Road/Area</span>
                                <FormError error={formErrors.block} />
                            </div>

                            <div className="relative">
                                <input
                                    type="text"
                                    value={addressValues?.house_number} // Changed from defaultValue to value
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.house_number ? `form_error_border` : `border-gray-200`}`}
                                    name="house_number"
                                    onChange={(e) => {
                                        setAddressValues({ ...addressValues, house_number: e.target.value }); // Update state
                                    }}
                                />
                                <span className="input_placeholder input_placeholder_houseNumber">House/Flat No</span>
                                <FormError error={formErrors.house_number} />
                            </div>

                            <div className="relative">
                                <input
                                    type="text"
                                    value={addressValues?.location_name || ""} // Changed from defaultValue to value
                                    placeholder=" "
                                    className={`input_field mt-1 block w-full ${formErrors?.location_name ? `form_error_border` : `border-gray-200`}`}
                                    name="location_name"
                                    onChange={(e) => {
                                        setAddressValues({ ...addressValues, location_name: e.target.value }); // Update state
                                        getLatLng(e.target.value); // Ensure to get lat/lng if needed
                                    }}
                                    readOnly
                                />
                                <span className="input_placeholder input_placeholder_location">Location{"'"}s Name</span>
                                <FormError error={formErrors.location_name} />
                            </div>

                            <div className="relative w-full" ref={dropdownRefAddressType}>
                                <input
                                    type="text"
                                    className={`input_field mt-1 block w-full select_quantity ${formErrors?.type
                                        ? `form_error_border`
                                        : `border-gray-200`
                                        }`}
                                    placeholder=" "
                                    name="type"
                                    value={addressTypeOption}
                                    onFocus={handleAddressTypeSelect}
                                />
                                <span className="input_placeholder input_placeholder_addresstype">Address Type</span>
                                <FormError error={formErrors.type} />
                                {isAddressTypeVisible && (
                                    <div className="absolute left-0 right-0 z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                                        <ul className="py-2">
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Home")}
                                            >
                                                {"Home"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Work")}
                                            >
                                                {"Work"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Friends and family")}
                                            >
                                                {"Friends and family"}
                                            </li>
                                            <li
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleAddressTypeClick("Other")}
                                            >
                                                {"Other"}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                {/* Action Buttons */}
                <div className="flex flex-col md:flex-row justify-between p-6 pt-0">
                    <div className="flex gap-2 items-center w-full">
                        <input type="checkbox" name="" value="" />
                        <span className="subtext">Save address for smooth process</span>
                    </div>
                    <div className="w-full lg:w-4/12 flex gap-3 py-6 justify-end service_buttons">
                        <button className="btn_secondary cancel" onClick={() => setShow(!show)}>Cancel</button>
                        <button className="btn_primary next" onClick={saveAddress}>Confirm Address</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAddressModal