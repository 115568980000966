/* eslint-disable react-hooks/exhaustive-deps */
// src/App.js
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/index";
import Footer from "../../../components/Footer/Footer";
import * as apiEndpoint from "../../../apis/postlogin/PostloginApis"
import Contact from "../../../assets/contactus.png"
import { isValidEmail, isValidPhoneNumber } from "../../../utilsfunction/RegexFunc";
import { getRequest, postRequest } from "../../../interceptor/AxiosConfig";
// import ErrorMessageHandling from "../../../utilsfunction/ErrorHandling";
import Success from "../../../components/Alert/Success";
import Error from "../../../components/Alert/Error";
import FormError from "../../../components/Alert/FormError";
import LocationIcon from "../../../assets/contactus/location.svg"
import EmailIcon from "../../../assets/contactus/email.svg"
import PhoneIcon from "../../../assets/contactus/phone.svg"
import { setMessage } from "../../../components/Reducers/MessageReducer";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const ContactUs = () => {

    const initialState = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
    };
    const [formValues, setFormValues] = useState(initialState);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const [formSubmitted, setFormSubmitted] = useState(false);

    // post api call for creating the user
    const registerUser = async () => {
        await postRequest(`${apiEndpoint.contactAPIEndpoint}`, {
            first_name: formValues?.first_name,
            last_name: formValues?.last_name,
            email: formValues?.email,
            phone: "+91" + formValues?.phone,
            message: formValues?.message
        })
            .then((res) => {;
                if (res.data.success) {
                    setSuccessMessage('Form submitted successfully!');
                    setFormValues({
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        message: "",
                    });
                    setFormSubmitted(true);  // Hide form and show success message
                }
            })
            .catch((error) => {
                setErrorMessage(null)
            });
    };

    // setting the fields values
    const onHandleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // validating the fields
    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = "Email Id is required!";
        } else if (!isValidEmail(values.email)) {
            errors.email = "Email Id is not valid!";
        }
        if (!values.first_name.trim()) {
            errors.first_name = "First name is required!";
        }
        if (!values.last_name.trim()) {
            errors.last_name = "Last name is required!";
        }
        if (!values.phone.trim()) {
            errors.phone = "Phone number is required!";
        } else if (!isValidPhoneNumber(values.phone)) {
            errors.phone =
                "Phone number is not valid, it should be 10 digits with no characters!";
        }
        if (!values.message.trim()) {
            errors.message = "Message is required!";
        }
        return errors;
    };

    // function for handling create button event
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        // if there is no errors registerUser() function will be called
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            registerUser();
        }
    }, [formErrors, isSubmit]);

    const [contactUsData, setContactUsData] = useState({})

    const getContactUs = async () => {
        await getRequest(`${apiEndpoint.tcPPAPIEndpoint}`)
            .then((res) => {
                if (res.status === 200) {
                    setContactUsData(res.data.data)
                }
            })
            .catch((error) => {
                setErrorMessage(null)
                // setErrorMessage(ErrorMessageHandling(error));
            });
    };

    useEffect(() => {
        getContactUs()
    }, [])

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!location.pathname.includes("/dospeedo/login")) {
            dispatch(setMessage(null));
        }
    }, [location.pathname.includes("/dospeedo/login")])

    return (
        <div>
            <Header />
            <div className="container mx-auto min-h-screen px-0">
                <div className="bgColor heading_text h-64 p-6 lg:pl-24 xl:pl-24 pl-5 text-left">
                    Contact Us
                </div>
                <div className="container mx-auto">

                    {formSubmitted ? (
                        <div className="success-message bg-green-100 text-green-800 p-10 rounded-lg shadow-xl max-w-2xl mx-auto mt-16 text-center">
                            <h2 className="text-3xl font-bold mb-4">Thank you!</h2>
                            <p className="text-xl">We’ve received your message and will get back to you shortly.</p>
                        </div>                    
                                        
                    ) : (
                        <div className="contactform bg-white shadow-lg mt-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 md:p-8 lg:p-8 xl:p-8 p-4 md:gap-4 lg:gap-4 xl:gap-4 gap-8">
                                <div className="flex justify-center items-center" >
                                    <img
                                        src={Contact}
                                        alt="Holding Hands"
                                        className="w-full md:h-72 lg:h-96 xl:h-96 h-52"
                                    />
                                </div>
                                <div className="flex flex-col md:gap-8 lg:gap-8 xl:gap-8 gap-0 w-full">
                                    <div className="flex space-x-4 w-full fieldGroup">
                                        <div className="relative w-full">
                                            <input
                                                type="text"
                                                placeholder=" "
                                                className="contact_fields border border-b-2 border-t-0 border-x-0 p-2 w-full"
                                                value={formValues?.first_name || ""}
                                                name="first_name"
                                                onChange={(e) => onHandleChange(e)}
                                            />
                                            <span className="contactinput_placeholder contactinput_placeholder_fn">First Name</span>
                                            <FormError error={formErrors.first_name} />
                                        </div>

                                        <div className="relative w-full">
                                            <input
                                                type="text"
                                                placeholder=" "
                                                className="contact_fields border border-b-2 border-t-0 border-x-0 w-full p-2"
                                                value={formValues?.last_name || ""}
                                                name="last_name"
                                                onChange={(e) => onHandleChange(e)}
                                            />
                                            <span className="contactinput_placeholder contactinput_placeholder_ln">Last Name</span>
                                            <FormError error={formErrors.last_name} />
                                        </div>
                                    </div>
                                    <div className="flex space-x-4 w-full fieldGroup">
                                        <div className="relative w-full">
                                            <input
                                                type="text"
                                                placeholder=" "
                                                className="contact_fields border border-b-2 border-t-0 border-x-0 p-2 w-full"
                                                value={formValues?.phone || ""}
                                                name="phone"
                                                onChange={(e) => onHandleChange(e)}
                                            />
                                            <span className="contactinput_placeholder contactinput_placeholder_mobile">Mobile Number</span>
                                            <FormError error={formErrors.first_name} />
                                        </div>
                                        <div className="relative w-full">
                                            <input
                                                type="email"
                                                placeholder=" "
                                                className="contact_fields border border-b-2 border-t-0 border-x-0 p-2 w-full"
                                                value={formValues?.email || ""}
                                                name="email"
                                                onChange={(e) => onHandleChange(e)}
                                            />
                                            <span className="contactinput_placeholder contactinput_placeholder_email">Email</span>
                                            <FormError error={formErrors.first_name} />
                                        </div>
                                    </div>
                                    <div className="relative w-full">
                                        <textarea
                                            placeholder=" "
                                            className="contact_fields textarea p-2 w-full"
                                            rows="4"
                                            name="message"
                                            onChange={(e) => onHandleChange(e)}
                                            value={formValues?.message || ""}
                                        ></textarea>
                                        <span className="contactinput_placeholder contactinput_placeholder_message">Your message...</span>
                                        <FormError error={formErrors.first_name} />
                                    </div>
                                    <div className="w-full flex justify-end">
                                        <div className="flex justify-end md:w-3/12 lg:w-3/12 xl:w-3/12 w-4/12"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            <button
                                                type="submit"
                                                className="btn_primary"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                {errorMessage && <Error errorMessage={errorMessage} />}
                {successMessage && <Success successMessage={successMessage} />}
                <div className="grid grid-cols-1 md:grid-cols-3 p-4 gap-4 mt-10 mb-10 md:px-10 lg:px-20 xl:px-20 px-5">
                    <div className="flex flex-col items-center">
                        <img src={LocationIcon} alt="Location" />

                        <p className="heading_text2">{contactUsData["contact-us-location"]?.title}</p>
                        <p className="content_text2" dangerouslySetInnerHTML={{ __html: contactUsData["contact-us-location"]?.description }}>
                        </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={EmailIcon} alt="Email" />
                        <p className="heading_text2">{contactUsData["contact-us-email"]?.title}</p>
                        <p className="content_text2" dangerouslySetInnerHTML={{ __html: contactUsData["contact-us-email"]?.description }}></p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={PhoneIcon} alt="Phone" />
                        <p className="heading_text2">{contactUsData["contact-us-phone"]?.title}</p>
                        <p className="content_text2" dangerouslySetInnerHTML={{ __html: contactUsData["contact-us-phone"]?.description }}></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
